import { useCallback, useEffect, useRef, useState } from 'react';
import updateIn from 'simple-update-in';

import './VolumeControl.css';

import debug from '../debug';
import styleConsole from '../utils/styleConsole';

const log = debug('<VolumeControl>');

const VolumeControl = ({ onChange, value }) => {
  const [listening, setListening] = useState();
  const anchorsRef = useRef({});
  const boxRef = useRef();
  const handleBoxRef = useRef();
  const lowerRef = useRef();
  const trackLevelRef = useRef();

  const handleChange = useCallback(
    nextValue => {
      onChange && value !== nextValue && onChange(nextValue);

      const { current: lower } = lowerRef;
      const { current: trackLevel } = trackLevelRef;

      lower.style.height = `${nextValue * 100}%`;
      trackLevel.style.height = `${nextValue * 100}%`;
    },
    [onChange, value]
  );

  useEffect(() => handleChange(value), [handleChange, value]);

  const handlePointerDown = useCallback(
    event => {
      log(
        `%c#${event.pointerId}%c %c${event.type}%c`,
        ...styleConsole('green'),
        ...styleConsole('gray'),
        ...styleConsole('yellow', 'black')
      );

      const { clientY } = event;

      anchorsRef.current = updateIn(anchorsRef.current, ['' + event.pointerId], () => ({
        handleOffsetTop: handleBoxRef.current.offsetTop,
        pointerClientY: clientY
      }));

      setListening(true);
    },
    [anchorsRef, setListening]
  );

  const handlePointerMove = useCallback(
    event => {
      if (!(event.pointerId in anchorsRef.current)) {
        return;
      }

      const { current: box } = boxRef;
      const { handleOffsetTop, pointerClientY } = anchorsRef.current[event.pointerId];

      const deltaY = event.clientY - pointerClientY;
      const percentage = box ? Math.max(0, Math.min(1, 1 - (handleOffsetTop + deltaY) / box.clientHeight)) : 1;

      log(
        [
          `%c#${event.pointerId}%c %c${event.type}%c Moved to %c${percentage * 100}%%%c`,
          ...styleConsole('gray'),
          ...styleConsole('yellow', 'black'),
          ...styleConsole('purple'),
          {
            boxClientTop: box && box.clientTop
          }
        ],
        anchorsRef.current,
        event
      );

      handleChange(percentage);
    },
    [handleChange]
  );

  const handlePointerUp = useCallback(
    event => {
      const anchors = (anchorsRef.current = updateIn(anchorsRef.current, ['' + event.pointerId]));

      log(
        [
          `%c#${event.pointerId}%c: %c${event.type}%c anchors length is %c${Object.keys(anchors).length}%c`,
          ...styleConsole('gray'),
          ...styleConsole('yellow', 'black'),
          ...styleConsole('purple'),
          { anchors: anchorsRef.current }
        ],
        anchorsRef.current
      );

      if (!Object.keys(anchors).length) {
        setListening(false);
      }
    },
    [anchorsRef, setListening]
  );

  useEffect(() => {
    if (listening) {
      log(`Start listening to global events`);

      window.addEventListener('pointermove', handlePointerMove, { passive: true });
      window.addEventListener('pointerup', handlePointerUp);
      window.addEventListener('pointerleave', handlePointerUp);

      return () => {
        log(`Stop listening to global events`);

        window.removeEventListener('pointermove', handlePointerMove, { passive: true });
        window.removeEventListener('pointerup', handlePointerUp);
        window.removeEventListener('pointerleave', handlePointerUp);
      };
    }
  }, [handlePointerMove, handlePointerUp, listening]);

  const handleLowerClick = useCallback(() => {
    // handleChange((Math.ceil(value / 0.1) - 1) * 0.1);

    const percentage = value * 100;

    handleChange((Math.ceil(percentage / 10) - 1) / 10);
  }, [handleChange, value]);

  const handleUpperClick = useCallback(() => {
    // handleChange((Math.floor(value / 0.1) + 1) * 0.1);

    const percentage = value * 100;

    handleChange((Math.floor(percentage / 10) + 1) / 10);
  }, [handleChange, value]);

  return (
    <div className="volume-control" title={`${~~(value * 100)}%`}>
      <div className="volume-control__track">
        <div className="volume-control__track-level" ref={trackLevelRef} />
      </div>
      <div className="volume-control__box" onPointerDown={handlePointerDown} ref={boxRef}>
        <button className="volume-control__upper" onClick={handleUpperClick} />
        <div className="volume-control__handle-box" ref={handleBoxRef}>
          <div className="volume-control__handle-bounding-box">
            <div className="volume-control__handle" />
          </div>
        </div>
        <button className="volume-control__lower" onClick={handleLowerClick} ref={lowerRef} />
      </div>
    </div>
  );
};

export default VolumeControl;
