import { useCallback } from 'react';

import './SelectableDevice.css';

function getLabel(device) {
  return device.label
    .split(/[()]/u)
    .map(token => token.trim())
    .filter(token => token);
}

const Icon = ({ icon }) => {
  return <i className={`selectable-device__icon ms-Icon ms-Icon--${icon}`} />;
};

const DeviceIcon = ({ device }) => {
  const { kind, label } = device;

  if (kind === 'audioinput') {
    if (/^Headset/iu.test(label)) {
      return <Icon icon="Headset" />;
    }

    return <Icon icon="Microphone" />;
  } else if (kind === 'audiooutput') {
    if (/^Speakers/iu.test(label)) {
      return <Icon icon="Speakers" />;
    } else {
      return <Icon icon="Headset" />;
    }
  } else if (kind === 'videoinput') {
    if (/^http/u.test(device.deviceId)) {
      return <Icon icon="Camera" />;
    } else if (/virtual/iu.test(device.label)) {
      return <Icon icon="BrowserScreenShot" />;
    } else if (/\sir\s/iu.test(device.label)) {
      return <Icon icon="NUIFace" />;
    } else if (/front/iu.test(device.label)) {
      return <Icon icon="FrontCamera" />;
    }

    return <Icon icon="Webcam2" />;
  }

  return <Icon icon="Camera" />;
};

const DeviceLabel = ({ device }) => {
  const { kind } = device;
  let parsedLabel = getLabel(device);

  if (kind === 'audioinput') {
    parsedLabel = parsedLabel.filter(token => !/(Headset|Microphone)/iu.test(token));
  } else if (kind === 'audiooutput') {
    parsedLabel = parsedLabel.filter(token => !/(Headset|Speakers)/iu.test(token));
  }

  return [parsedLabel[0], ...parsedLabel.slice(1).map(token => `(${token})`)].join(' ') || 'Default';
};

const SelectableDevice = ({ checked, device, disabled, onChange }) => {
  const handleChange = useCallback(
    ({ target: { checked } }) => {
      onChange({ checked, device });
    },
    [device, onChange]
  );

  return (
    <label className="selectable-device">
      <input
        checked={checked}
        className="selectable-device__input"
        disabled={disabled}
        onChange={handleChange}
        type="checkbox"
      />
      <span className="selectable-device__badge">
        <div className="selectable-device__icon-box">
          <DeviceIcon device={device} />
        </div>
        <span className="selectable-device__label">
          <DeviceLabel device={device} />
        </span>
      </span>
    </label>
  );
};

export default SelectableDevice;
