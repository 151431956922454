export default function diff(x, y) {
  const added = y.filter(element => !~x.indexOf(element));
  const removed = x.filter(element => !~y.indexOf(element));

  return {
    added,
    changed: !!(added.length || removed.length),
    removed
  };
}
