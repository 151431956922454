import { useCallback, useEffect, useState } from 'react';

import Context from './Context';
import useObserveEvent from '../useObserveEvent';

function contextFromChatroom(chatroom) {
  return { ports: chatroom ? chatroom.ports : {}, userId: chatroom ? chatroom.localId : '' };
}

const ChatroomProvider = ({ chatroom, children }) => {
  const [context, setContext] = useState(() => contextFromChatroom(chatroom));

  const handlePortsChange = useCallback(() => {
    setContext(contextFromChatroom(chatroom));
  }, [chatroom, setContext]);

  useObserveEvent(chatroom, 'portschange', handlePortsChange);

  useEffect(() => {
    handlePortsChange();
  }, [handlePortsChange]);

  return <Context.Provider value={context}>{children}</Context.Provider>;
};

export default ChatroomProvider;
