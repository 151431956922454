import classNames from 'classnames';

import './IconToggleButton2.css';

const IconToggleButton = ({ checked, checkedIcon, className, disabled, icon, mode, onChange, title }) => {
  return (
    <div
      className={classNames('icon-toggle-button2', { 'icon-toggle-button2--warning': mode === 'warning' }, className)}
    >
      <input
        checked={!!checked}
        className="icon-toggle-button2__input"
        disabled={disabled}
        onChange={onChange}
        title={title}
        type="checkbox"
      />
      <div className="icon-toggle-button2__ring">
        <div className="icon-toggle-button2__body">
          <i className={`icon-toggle-button2__icon ms-Icon ms-Icon--${(checked && checkedIcon) || icon}`} />
        </div>
      </div>
    </div>
  );
};

export default IconToggleButton;
