import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';

import './App5.css';

import AppProvider from './hooks/app/AppProvider';
import AudioNodeAnalyser from './ui/SoundDetector4';
import AudioOutputDevicesProvider from './hooks/AudioOutputDevices/Provider';
import AudioSource from './ui/AudioSource';
import BigButtonScreen from './BigButtonScreen';
import Chatroom from './systems/Chatroom';
import ChatroomProvider from './hooks/Chatroom/Provider';
import ConnectNode from './hooks/AudioOutputNode/ConnectNode';
import debug from './debug';
import DeviceCaptureProvider from './hooks/DeviceCapture/Provider';
import GuestModeFlow from './GuestModeFlow';
import IconToggleButton2 from './ui/IconToggleButton2';
import LocalVideo from './ui/LocalVideo2';
import PeerAudioGraphProvider from './hooks/PeerAudioGraph/Provider';
import PeerConnectionProvider from './hooks/PeerConnection/Provider';
import PeerVideo from './ui/PeerVideo2';
import RegisterScreen from './RegisterScreen';
import SideBar from './ui/SideBar';
import SignInScreen from './SignInScreen';
import SplashScreen from './SplashScreen';
import styleConsole from './utils/styleConsole';
import useAsyncEffect from './hooks/useAsyncEffect';
import useAudioContext from './hooks/AudioOutputNode/useAudioContext';
import useChatroomPorts from './hooks/Chatroom/usePorts';
import useChatroomUserId from './hooks/Chatroom/useUserId';
import useDeviceCaptureStreams from './hooks/DeviceCapture/useStreams';
import useDeviceSelector from './hooks/app/useDeviceSelector';
import useEffectMany from './hooks/useEffectMany';
import useFlow from './hooks/app/useFlow';
import useLowDataMode from './hooks/app/useLowDataMode';
import useMutedVideoStreamIds from './hooks/app/useMutedVideoStreamIds';
import useMuteMicrophone from './hooks/app/useMuteMicrophone';
import useOutputVolume from './hooks/app/useOutputVolume';
import usePeerAudioGraphs from './hooks/PeerAudioGraph/useGraphs';
import usePeerConnectionStreams from './hooks/PeerConnection/useStreams';
import usePeerIdsWithMutedAudio from './hooks/app/usePeerIdsWithMutedAudio';
import useSelectedAudioOutputDeviceId from './hooks/app/useSelectedAudioOutputDeviceId';
import VolumeControl from './ui/VolumeControl';

const { location } = window;
const log = debug('<CoreApp>', 'orange');

const VideoGrid = () => {
  const [localId] = useChatroomUserId();
  const [localStreams] = useDeviceCaptureStreams();
  const [peerStreams] = usePeerConnectionStreams();

  const outputs = [
    ...localStreams
      .filter(({ stream }) => stream.getVideoTracks().length)
      .map(({ stream }) => ({ id: stream.getVideoTracks()[0].id, label: 'You', peerId: localId, stream })),
    ...Object.entries(peerStreams).reduce(
      (allPeerStreams, [peerId, streams]) => [
        ...allPeerStreams,
        ...Object.entries(streams)
          .filter(([_, { kind }]) => kind === 'video')
          .reduce(
            (peerStreams, [streamId, { kind, stream, track }]) => [
              ...peerStreams,
              {
                kind,
                label: peerId,
                peerId,
                stream,
                streamId,
                track
              }
            ],
            []
          )
      ],
      []
    )
  ].sort(({ id: x }, { id: y }) => (x > y ? 1 : x < y ? -1 : 0));

  debug('<VideoGrid>')(['render'], { inputs: { localStreams, peerStreams }, outputs });

  return new Array(4).fill().map((_, index) => {
    const output = outputs[index];
    const { label, peerId, stream, streamId } = output || {};

    const hasAudioTrack = Object.entries(peerStreams).some(
      entry => entry[0] === peerId && Object.values(entry[1]).some(entry => entry.kind === 'audio')
    );

    return (
      <div className="app__video-grid__video" key={streamId || index}>
        {!!output &&
          (localId === peerId ? (
            <LocalVideo className="app__video-grid__video-source" label={label} srcObject={stream} />
          ) : (
            <PeerVideo
              className="app__video-grid__video-source"
              hasAudioTrack={hasAudioTrack}
              label={label}
              peerId={peerId}
              srcObject={stream}
              streamId={streamId}
            />
          ))}
      </div>
    );
  });
};

const PlayPeerAudio = () => {
  const [graphs] = usePeerAudioGraphs();
  const [outputVolume] = useOutputVolume();
  const [sinkId] = useSelectedAudioOutputDeviceId();
  const [peerIdsWithMutedAudio] = usePeerIdsWithMutedAudio();

  debug('<PlayPeerAudio>')('Playing peer audio graphs', { graphs });

  const fullVolume = outputVolume === 1;

  return graphs.map(graph => {
    const muted = peerIdsWithMutedAudio.includes(graph.peerId);

    return (
      <Fragment key={graph.key}>
        {fullVolume || muted ? false : <ConnectNode key={graph.key} node={graph.finalNode} />}
        {/* <AudioSource> is required for connecting WebRTC streams even we are just outputting through another MediaStreamAudioDestinationNode. */}
        <AudioSource muted={!fullVolume || muted} sinkId={sinkId} srcObject={graph.stream} />
      </Fragment>
    );
  });
};

const SliderVolumeControl = () => {
  const [outputVolume, setOutputVolume] = useOutputVolume();

  log(`SliderVolumeControl`, { outputVolume });

  return <VolumeControl onChange={setOutputVolume} value={outputVolume} />;
};

const PeerVolumeControl = () => {
  // return /iP(ad|hone|od)/.test(window.navigator.userAgent) ? false : <SliderVolumeControl />;
  return <SliderVolumeControl />;
};

const CoreApp = () => {
  const [, setFlow] = useFlow();
  const [audioContext] = useAudioContext();
  const [lowDataMode] = useLowDataMode();
  const [muteMicrophone, setMuteMicrophone] = useMuteMicrophone();
  const [ports] = useChatroomPorts();
  const [streams] = useDeviceCaptureStreams();
  const [mutedVideoStreamIds] = useMutedVideoStreamIds();

  const handleClose = useCallback(() => setFlow(''), [setFlow]);
  const handleMuteMicrophoneChange = useCallback(({ target: { checked } }) => setMuteMicrophone(checked), [
    setMuteMicrophone
  ]);

  const { microphoneAnalyserNode, microphoneDestinationNode, microphoneGainNode } = useMemo(() => {
    const microphoneAnalyserNode = audioContext.createAnalyser();
    const microphoneDestinationNode = audioContext.createMediaStreamDestination();
    const microphoneGainNode = audioContext.createGain();

    microphoneGainNode.connect(microphoneDestinationNode);

    log(
      `%cAudioContext created%c with state %c${audioContext.state}%c`,
      ...styleConsole('green'),
      ...styleConsole('purple'),
      { audioContext, microphoneGainNode }
    );

    return { microphoneAnalyserNode, microphoneDestinationNode, microphoneGainNode };
  }, [audioContext]);

  useEffect(() => microphoneAnalyserNode.disconnect.bind(microphoneAnalyserNode), [microphoneAnalyserNode]);
  useEffect(() => microphoneDestinationNode.disconnect.bind(microphoneDestinationNode), [microphoneDestinationNode]);
  useEffect(() => microphoneGainNode.disconnect.bind(microphoneGainNode), [microphoneGainNode]);

  useEffect(() => {
    microphoneGainNode && microphoneGainNode.gain.setValueAtTime(muteMicrophone ? 0 : 1, 0);
  }, [microphoneGainNode, muteMicrophone]);

  const { audioTracks, videoTracks } = useMemo(
    () =>
      streams
        .map(({ stream }) => ({ stream, track: stream.getTracks()[0] }))
        .reduce(
          ({ audioTracks, videoTracks }, { stream, track }) => {
            if (track) {
              const { kind } = track;
              const entry = { stream, track };

              if (kind === 'audio') {
                audioTracks.push(entry);
              } else if (kind === 'video') {
                videoTracks.push(entry);
              }
            }

            return { audioTracks, videoTracks };
          },
          { audioTracks: [], videoTracks: [] }
        ),
    [streams]
  );

  useEffectMany(
    audioTracks.map(({ stream }) => stream),
    useCallback(
      stream => {
        const sourceNode = audioContext.createMediaStreamSource(stream);

        sourceNode.connect(microphoneAnalyserNode);
        sourceNode.connect(microphoneGainNode);

        return () => {
          sourceNode.disconnect(microphoneAnalyserNode);
          sourceNode.disconnect(microphoneGainNode);
        };
      },
      [audioContext, microphoneAnalyserNode, microphoneGainNode]
    )
  );

  const sendTracks = useMemo(() => {
    const { stream } = microphoneDestinationNode;

    log(
      `Sending %c${stream.getTracks().length}%c audio tracks and %c${videoTracks.length}%c video tracks`,
      ...styleConsole('purple'),
      ...styleConsole('purple'),
      { microphoneDestinationNode, stream, tracks: stream.getTracks() }
    );

    return [{ stream, track: stream.getTracks()[0] }, ...videoTracks];
  }, [microphoneDestinationNode, videoTracks]);

  // const sendTracks = useMemo(() => {
  //   const { stream } = microphoneDestinationNode;

  //   log(
  //     `Sending %c${stream.getTracks().length}%c audio tracks and %c${videoTracks.length}%c video tracks`,
  //     ...styleConsole('purple'),
  //     ...styleConsole('purple'),
  //     { microphoneDestinationNode, stream, tracks: stream.getTracks() }
  //   );

  //   return [...(muteMicrophone ? [] : audioTracks), ...videoTracks];
  // }, [audioTracks, muteMicrophone, videoTracks]);

  const receiveTrackSelector = useCallback(streamId => !mutedVideoStreamIds.includes(streamId), [mutedVideoStreamIds]);

  log([`Render`], {
    hideVideoStreamIds: mutedVideoStreamIds,
    lowDataMode,
    ports,
    receiveTrackSelector,
    sendTracks,
    streams
  });

  const headsetOff = audioContext.state !== 'running' || muteMicrophone;

  return (
    <PeerConnectionProvider
      audioBitrate={lowDataMode ? 64 : undefined}
      ports={ports}
      sendTracks={sendTracks}
      receiveTrackSelector={receiveTrackSelector}
      videoBitrate={lowDataMode ? 384 : undefined}
    >
      <PeerAudioGraphProvider audioContext={audioContext}>
        <div className="app">
          <div className="app__video-grid">
            <VideoGrid />
          </div>
          <SideBar audioContext={audioContext} onClose={handleClose}>
            {/* <div style={{ color: 'white' }}>{audioContext.state.substr(0, 3)}</div> */}
            <PeerVolumeControl audioContext={audioContext} />
            <AudioNodeAnalyser analyser={microphoneAnalyserNode}>
              {level => {
                const noSound = !audioTracks.length || typeof level === 'undefined';

                return (
                  <IconToggleButton2
                    checked={headsetOff}
                    icon={
                      headsetOff
                        ? 'MicOff'
                        : noSound
                        ? 'MicOff2'
                        : level === 1
                        ? 'Volume3'
                        : level >= 0.5
                        ? 'Volume2'
                        : level
                        ? 'Volume1'
                        : 'Volume0'
                    }
                    mode={headsetOff ? 'warning' : ''}
                    onChange={handleMuteMicrophoneChange}
                    title={headsetOff ? 'Tap to unmute microphone' : noSound ? 'No audio devices' : ''}
                  />
                );
              }}
            </AudioNodeAnalyser>
          </SideBar>
          <PlayPeerAudio audioContext={audioContext} />
        </div>
      </PeerAudioGraphProvider>
    </PeerConnectionProvider>
  );
};

const CREATE_DEVICE_SELECTOR = () => ({ kind }) => kind === 'audioinput' || kind === 'videoinput';

async function fetchAuthTokenCheck(signal) {
  try {
    const authToken = sessionStorage.getItem('authtoken');

    if (!authToken) {
      return false;
    }

    const res = await fetch(`/api/authtokencheck?${new URLSearchParams({ token: authToken })}`, {
      method: 'POST',
      signal
    });

    if (!res.ok) {
      return false;
    }

    return res.text();
  } catch (err) {
    console.error(err);

    return false;
  }
}

const ProtectedApp = ({ chatroom }) => {
  const createStreamSelector = useDeviceSelector();

  return (
    <ChatroomProvider chatroom={chatroom}>
      <DeviceCaptureProvider createDeviceSelector={CREATE_DEVICE_SELECTOR} createStreamSelector={createStreamSelector}>
        <AudioOutputDevicesProvider>
          <CoreApp />
        </AudioOutputDevicesProvider>
      </DeviceCaptureProvider>
    </ChatroomProvider>
  );
};

const ReadyFlow = ({ onClose, token, totp }) => {
  const [chatroom, setChatroom] = useState();

  useAsyncEffect(
    async signal => {
      const urlSearchParams = new URLSearchParams(token ? { token } : { totp });
      const webSocketURL = `${location.protocol === 'http:' ? 'ws:' : 'wss:'}//${
        location.host
      }/api/stream?${urlSearchParams}`;

      const nextChatroom = new Chatroom(webSocketURL);

      nextChatroom.addEventListener(
        'close',
        async () => {
          await new Promise(resolve => setTimeout(resolve, 500));

          !signal.aborted && onClose();
        },
        {
          once: true
        }
      );

      setChatroom(nextChatroom);

      return () => {
        log('%cChatroom object closed%c', ...styleConsole('red'));
        nextChatroom.close();
      };
    },
    [onClose, token]
  );

  return !!chatroom && <ProtectedApp chatroom={chatroom} />;
};

const AppFlow = () => {
  const [flow, setFlow] = useFlow();
  const [lastKnownGoodAuthToken, setLastKnownGoodAuthToken] = useState('');

  const handleAuthenticateFailed = useCallback(() => setFlow('sign in'), [setFlow]);
  const handleAuthenticateSucceeded = useCallback(
    token => {
      setLastKnownGoodAuthToken(token);
      setFlow('ready');
    },
    [setFlow, setLastKnownGoodAuthToken]
  );

  const handleGuestModeClick = useCallback(() => setFlow('guest mode'), [setFlow]);
  const handleGuestModeClose = useCallback(() => setFlow('sign in'), [setFlow]);

  const handleSignInSuccess = useCallback(() => {
    setLastKnownGoodAuthToken('');
    setFlow('authenticate');
  }, [setFlow, setLastKnownGoodAuthToken]);

  const handleRegisterClick = useCallback(() => setFlow('register'), [setFlow]);
  const handleRegisterClose = useCallback(() => setFlow('authenticate'), [setFlow]);

  const handleReadyClose = useCallback(() => setFlow('authenticate'), [setFlow]);

  return flow === 'ready' ? (
    <ReadyFlow onClose={handleReadyClose} token={lastKnownGoodAuthToken} />
  ) : flow === 'sign in' ? (
    <SignInScreen
      onGuestModeClick={handleGuestModeClick}
      onRegisterClick={handleRegisterClick}
      onSuccess={handleSignInSuccess}
    />
  ) : flow === 'register' ? (
    <RegisterScreen onClose={handleRegisterClose} />
  ) : flow === 'guest mode' ? (
    <GuestModeFlow onClose={handleGuestModeClose} onSuccess={handleAuthenticateSucceeded} />
  ) : (
    <AuthenticateFlow onFailed={handleAuthenticateFailed} onSucceed={handleAuthenticateSucceeded} />
  );
};

const AuthenticateFlow = ({ onFailed, onSucceed }) => {
  useAsyncEffect(
    async signal => {
      const authToken = await fetchAuthTokenCheck(signal);

      if (signal.aborted) {
        return;
      }

      if (authToken) {
        onSucceed(authToken);
      } else {
        onFailed();
      }
    },
    [onFailed, onSucceed]
  );

  return <BigButtonScreen disabled={true} icon="Fingerprint" label="Authenticating" />;
};

const BasicApp = () => {
  return (
    <AppProvider>
      <SplashScreen>
        <AppFlow />
      </SplashScreen>
    </AppProvider>
  );
};

export default BasicApp;
