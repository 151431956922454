import AbortController from 'abort-controller';
import createDeferred from 'p-defer';
import EventTarget, { defineEventAttribute } from 'event-target-shim';
import random from 'math-random';
import updateIn from 'simple-update-in';

import createEventSubscription from '../utils/createEventSubscription';
import debugFlag from '../debugFlag';
import diff from '../data/sagas/utils/diff';
import filterMap from '../utils/filterMap';
import intersect from '../utils/intersect';
import randomColor from '../utils/randomColor';
import setMediaBitrates from '../utils/setMediaBitrates';
import styleConsole from '../utils/styleConsole';

const DEFAULT_TRACK_SELECTOR = () => true;
const NEGOTIATING_TIMEOUT = 5000;

function getPeerConnectionState(peerConnection) {
  return {
    canTrickleIceCandidates: peerConnection.canTrickleIceCandidates,
    connectionState: peerConnection.connectionState,
    iceConnectionState: peerConnection.iceConnectionState,
    iceGatheringState: peerConnection.iceGatheringState,
    signalingState: peerConnection.signalingState
  };
}

const LOG_HIGHLIGHT_STYLE = styleConsole('purple');
const LOG_LOWLIGHT_STYLE = styleConsole('#ccc', 'black');
const LOG_SUBTLE_STYLE = styleConsole('transparent', '#ccc');
const LOG_WARNING_STYLE = styleConsole('#e00');

// TODO: Determines why when a stream is added, iPad send remote-reconnect.

export default class StreamingPeerConnection extends EventTarget {
  constructor(serverOptions, port, polite, { audioBitrate, videoBitrate } = {}) {
    super();

    this.sessionColor = randomColor();
    this.sessionId = random().toString().substr(2, 4);
    this.polite = polite;
    this.port = port;
    this.incomingStreams = {};
    this.audioBitrate = audioBitrate || Infinity;
    this.videoBitrate = videoBitrate || Infinity;

    this.nextDeferred = createDeferred();
    this.availableOutgoingTracks = [];
    this.senders = {};

    this.incomingTrackSelector = DEFAULT_TRACK_SELECTOR;

    this.abortController = new AbortController();

    this.start(serverOptions);
  }

  close() {
    this.log('%cCLOSING a RTCPeerConnection%c', ...styleConsole('yellow', 'black'));
    this.abortController.abort();
    this.signalNext();
  }

  getSenders() {
    return Object.values(this.senders);
  }

  log(message, ...args) {
    debugFlag.streamingPeerConnection &&
      console.log(
        `%c${this.port.id}%c#${this.sessionId}%c %c: ${message}`,
        'background: green; border-radius: 4px 0 0 4px; color: white; padding: 2px 4px;',
        'background: #ccc; color: #666; padding: 2px 4px;',
        `background: ${this.sessionColor}; border-radius: 0 4px 4px 0; padding: 2px 4px;`,
        '',
        ...args
      );
  }

  setAvailableTracks(availableOutgoingTracks) {
    const numAudioTracks = availableOutgoingTracks.filter(({ track: { kind } }) => kind === 'audio').length;
    const numVideoTracks = availableOutgoingTracks.filter(({ track: { kind } }) => kind === 'video').length;

    this.log(
      `%csetAvailableTracks%c called, %c${numAudioTracks}%c audio tracks, %c${numVideoTracks}%c video tracks`,
      ...LOG_HIGHLIGHT_STYLE,
      ...LOG_HIGHLIGHT_STYLE,
      ...LOG_HIGHLIGHT_STYLE,
      {
        prevAvailableOutgoingTracks: this.availableOutgoingTracks,
        availableOutgoingTracks
      }
    );

    this.availableOutgoingTracks = availableOutgoingTracks;
    this.signalNext();
  }

  setTrackSelector(incomingTrackSelector) {
    this.log(`%csetTrackSelector%c called`, ...LOG_HIGHLIGHT_STYLE, {
      prevIncomingTrackSelector: this.incomingTrackSelector,
      trackSelector: incomingTrackSelector
    });

    this.incomingTrackSelector = incomingTrackSelector;
    this.signalNext();
  }

  signalNext() {
    const resolve = this.nextDeferred.resolve.bind(this.nextDeferred);

    this.nextDeferred = createDeferred();
    resolve();
  }

  async start(serverOptions) {
    const {
      abortController: { signal: abortSignal },
      port
    } = this;

    const peerConnection = new RTCPeerConnection(serverOptions);

    this.log(`%cCREATING a new RTCPeerConnection%c`, ...styleConsole('yellow', 'black'), {
      peerConnection,
      context: this
    });

    let events = {};
    let messages = {};
    let negotiatingSince = 0;

    const subscription = createEventSubscription();

    subscription.subscribe(port, 'message', ({ data }) => {
      const { payload, type } = data;

      this.log(`%cincoming message%c %c${type}%c`, ...LOG_SUBTLE_STYLE, ...LOG_LOWLIGHT_STYLE, { payload });
      (messages[type] || (messages[type] = [])).push(payload);

      this.signalNext();
    });

    subscription.subscribe(
      peerConnection,
      [
        'connectionstatechange',
        'icecandidate',
        'iceconnectionstatechange',
        'icegatheringstatechange',
        'negotiationneeded',
        'signalingstatechange',
        'track'
      ],
      event => {
        let { type } = event;

        if (type === 'connectionstatechange') {
          this.log(
            `received %cconnectionstatechange%c event of value %c${peerConnection.connectionState}%c`,
            ...LOG_LOWLIGHT_STYLE,
            ...LOG_LOWLIGHT_STYLE
          );
        } else if (type === 'iceconnectionstatechange') {
          this.log(
            `received %ciceconnectionstatechange%c event of value %c${peerConnection.iceConnectionState}%c`,
            ...LOG_LOWLIGHT_STYLE,
            ...LOG_LOWLIGHT_STYLE
          );
        } else if (type === 'icegatheringstatechange') {
          this.log(
            `received %cicegatheringstatechange%c event of value %c${peerConnection.iceGatheringState}%c`,
            ...LOG_LOWLIGHT_STYLE,
            ...LOG_LOWLIGHT_STYLE
          );
        } else if (type === 'signalingstatechange') {
          this.log(
            `received %csignalingstatechange%c event of value %c${peerConnection.signalingState}%c`,
            ...LOG_LOWLIGHT_STYLE,
            ...LOG_LOWLIGHT_STYLE
          );
        } else if (type === 'icecandidate') {
          this.log(`received %cicecandidate%c event`, LOG_LOWLIGHT_STYLE, { candidate: event.candidate, event });
        } else if (type === 'icecandidate') {
          this.log(`received %ctrack%c event`, ...LOG_LOWLIGHT_STYLE, {
            streams: event.streams,
            track: event.track,
            event
          });
        } else {
          this.log(`received %c${type}%c event`, ...LOG_LOWLIGHT_STYLE, { event });
        }

        if (
          type === 'connectionstatechange' ||
          type === 'iceconnectionstatechange' ||
          type === 'icegatheringstatechange' ||
          type === 'signalingstatechange'
        ) {
          type = 'connectionstatechange';
        }

        (events[type] || (events[type] = [])).push(event);

        this.signalNext();
      }
    );

    try {
      port.start();

      let requestAvailableTracks = true;
      let announcedOutgoingStreamIds = null;
      let availableIncomingStreams = {};
      let requestedIncomingStreamIds = [];
      let requestedOutgoingStreamIds = [];
      let loop = Infinity;

      while (!abortSignal.aborted && --loop) {
        let nextIncomingStreams = this.incomingStreams;

        const availableOutgoingStreamIds = this.availableOutgoingTracks.map(entry => entry.stream.id);
        const requestingIncomingStreamIds = Object.entries(availableIncomingStreams)
          .filter(([streamId, { kind }]) => this.incomingTrackSelector(streamId, { kind }))
          .map(([streamId]) => streamId)
          .sort();

        const { added: addStreamIds, removed: removeStreamIds } = diff(
          Object.keys(this.senders),
          intersect(availableOutgoingStreamIds, requestedOutgoingStreamIds)
        );

        const { changed: announceOutgoingTracksNeeded } = announcedOutgoingStreamIds
          ? diff(announcedOutgoingStreamIds, availableOutgoingStreamIds)
          : { changed: true };

        const { changed: announceRequestedIncomingTracksNeeded } = diff(
          requestedIncomingStreamIds,
          requestingIncomingStreamIds
        );

        this.log(`%cStreamingPeerConnection.loop%c`, ...LOG_SUBTLE_STYLE, {
          events,
          messages,
          senders: this.senders,
          availableTracks: this.availableOutgoingTracks,
          incoming: {
            available: availableIncomingStreams,
            incoming: this.incomingStreams,
            requested: requestedIncomingStreamIds,
            requesting: requestingIncomingStreamIds
          },
          outgoing: {
            announced: announcedOutgoingStreamIds,
            available: availableOutgoingStreamIds.reduce(
              (fancy, id) => ({
                ...fancy,
                [id]: { kind: (this.availableOutgoingTracks.find(entry => entry.stream.id === id) || {}).kind }
              }),
              {}
            ),
            changes: {
              addStreamIds,
              removeStreamIds
            },
            rawAvailable: availableOutgoingStreamIds,
            requested: requestedOutgoingStreamIds,
            sending: Object.keys(this.senders)
          }
        });

        if ((events.connectionstatechange || []).length) {
          events.connectionstatechange.shift();

          this.connectionState = getPeerConnectionState(peerConnection);

          this.dispatchEvent(new CustomEvent('connectionstatechange'));

          // TODO: Restart ICE if iceConnectionState === 'failed'
        } else if ((events.negotiationneeded || []).length) {
          events.negotiationneeded.shift();

          this.log(`sending "rts-offer"`);

          negotiatingSince = Date.now();

          port.postMessage({ type: 'rts-offer' });
        } else if ((messages['rts-offer'] || []).length) {
          messages['rts-offer'].shift();

          if (Date.now() > negotiatingSince + NEGOTIATING_TIMEOUT || !this.polite) {
            this.log(
              `received %crts-offer%c and sending %ccts-offer%c`,
              ...LOG_HIGHLIGHT_STYLE,
              ...LOG_HIGHLIGHT_STYLE
            );

            negotiatingSince = Date.now();

            this.log(`sending %ccts-offer%c`, ...LOG_HIGHLIGHT_STYLE, {
              audioBitrate: this.audioBitrate,
              videoBitrate: this.videoBitrate
            });

            port.postMessage({
              payload: { audioBitrate: this.audioBitrate, videoBitrate: this.videoBitrate },
              type: 'cts-offer'
            });
          } else {
            this.log(
              `received %crts-offer%c but %cnot sending cts-offer%c`,
              ...LOG_HIGHLIGHT_STYLE,
              ...LOG_WARNING_STYLE
            );
          }
        } else if ((messages['cts-offer'] || []).length) {
          const { audioBitrate: peerAudioBitrate, videoBitrate: peerVideoBitrate } = messages['cts-offer'].shift();

          // JSON.serialize will turn "Infinity" into "null".
          const expectedAudioBitrate = Math.min(peerAudioBitrate || Infinity, this.audioBitrate);
          const expectedVideoBitrate = Math.min(peerVideoBitrate || Infinity, this.videoBitrate);

          this.log(`received %ccts-offer%c`, ...LOG_HIGHLIGHT_STYLE, {
            audioBitrate: this.audioBitrate,
            videoBitrate: this.videoBitrate,
            expectedAudioBitrate,
            expectedVideoBitrate,
            peerAudioBitrate,
            peerVideoBitrate
          });

          let offer = await peerConnection.createOffer();

          if (abortSignal.aborted) {
            break;
          }

          await peerConnection.setLocalDescription(offer);

          offer.sdp = setMediaBitrates(offer.sdp, {
            audio: expectedAudioBitrate,
            video: expectedVideoBitrate
          });

          if (abortSignal.aborted) {
            break;
          }

          port.postMessage({
            payload: {
              audioBitrate: expectedAudioBitrate,
              offer,
              videoBitrate: expectedVideoBitrate
            },
            type: 'offer'
          });
        } else if ((messages.offer || []).length) {
          const { audioBitrate, offer, videoBitrate } = messages.offer.shift();

          this.log(`received incoming %coffer%c`, ...LOG_HIGHLIGHT_STYLE, { audioBitrate, offer, videoBitrate });

          await peerConnection.setRemoteDescription(offer);

          if (abortSignal.aborted) {
            break;
          }

          let answer = await peerConnection.createAnswer();

          if (abortSignal.aborted) {
            break;
          }

          await peerConnection.setLocalDescription(answer);

          answer.sdp = setMediaBitrates(answer.sdp, {
            audio: audioBitrate,
            video: videoBitrate
          });

          if (abortSignal.aborted) {
            break;
          }

          port.postMessage({ payload: answer, type: 'answer' });

          negotiatingSince = 0;
        } else if ((messages.answer || []).length) {
          const answer = messages.answer.shift();

          this.log(`received incoming %canswer%c`, ...LOG_HIGHLIGHT_STYLE, { answer });

          await peerConnection.setRemoteDescription(answer);

          negotiatingSince = 0;
        } else if ((events.icecandidate || []).length) {
          const { candidate } = events.icecandidate.shift();

          this.log(`sending outgoing ICE candidate`, { candidate });

          port.postMessage({ payload: candidate, type: 'icecandidate' });
        } else if ((messages.icecandidate || []).length) {
          const candidate = messages.icecandidate.shift();

          this.log(`accepting incoming ICE candidate`, { candidate });

          candidate && (await peerConnection.addIceCandidate(candidate));
        } else if ((events.track || []).length) {
          const event = events.track.shift();
          const { streams, track } = event;

          for (let stream of streams) {
            nextIncomingStreams = updateIn(nextIncomingStreams, [stream.id, 'stream'], () => stream);
            nextIncomingStreams = updateIn(nextIncomingStreams, [stream.id, 'track'], () => track);

            const unsubscribe = subscription.subscribe(stream, 'removetrack', event => {
              (events.removetrack || (events.removetrack = [])).push({ event, streamId: stream.id, unsubscribe });
            });
          }

          this.log(
            `adding incoming track %c${streams[0].id}%c with %c${streams.length}%c streams`,
            ...LOG_HIGHLIGHT_STYLE,
            ...LOG_HIGHLIGHT_STYLE,
            {
              event,
              prevStreams: this.incomingStreams,
              requestingIncomingStreamIds,
              streams: nextIncomingStreams
            }
          );
        } else if ((events.removetrack || []).length) {
          const {
            event: { target },
            streamId,
            unsubscribe
          } = events.removetrack.shift();

          if (!target.active) {
            unsubscribe();

            nextIncomingStreams = updateIn(nextIncomingStreams, [streamId], ({ kind }) => ({ kind }));

            this.log(`removing incoming stream %c${streamId}%c`, ...LOG_HIGHLIGHT_STYLE, {
              prevStreams: this.incomingStreams,
              requestingIncomingStreamIds,
              streams: nextIncomingStreams
            });
          }
        } else if ((messages.reconnect || []).length) {
          // Note: we are not shifting the "reconnect" so we can shift it later and determines close reason.

          break;
        } else if ((addStreamIds.length || removeStreamIds.length) && peerConnection.signalingState === 'stable') {
          this.log(
            `%cavailableOutgoingStreamIds%c or %crequestedOutgoingStreamIds%c changed, adding %c${addStreamIds.length}%c tracks, removing %c${removeStreamIds.length}%c orphan tracks`,
            ...LOG_HIGHLIGHT_STYLE,
            ...LOG_HIGHLIGHT_STYLE,
            ...LOG_HIGHLIGHT_STYLE,
            ...LOG_HIGHLIGHT_STYLE,
            {
              currentStreamIds: Object.keys(this.senders).sort(),
              nextStreamIds: availableOutgoingStreamIds.sort(),
              details: {
                availableTracks: this.availableOutgoingTracks,
                currentSenders: this.senders
              }
            }
          );

          for (let streamId of removeStreamIds) {
            const sender = this.senders[streamId];

            peerConnection.removeTrack(sender);

            delete this.senders[streamId];
          }

          for (let streamId of addStreamIds) {
            const { stream, track } = this.availableOutgoingTracks.find(entry => entry.stream.id === streamId);

            this.senders[streamId] = peerConnection.addTrack(track, stream);
          }

          this.dispatchEvent(new CustomEvent('senderschange'));
        } else if (announceOutgoingTracksNeeded) {
          this.log(
            `announcing local streams %cavailable to watch%c with %c${availableOutgoingStreamIds.length}%c tracks`,
            ...LOG_HIGHLIGHT_STYLE,
            ...LOG_HIGHLIGHT_STYLE,
            {
              availableOutgoingStreamIds
            }
          );

          port.postMessage({
            payload: this.availableOutgoingTracks.reduce(
              (trackMap, { stream: { id }, track: { kind } }) => ({
                ...trackMap,
                [id]: {
                  kind
                }
              }),
              {}
            ),
            type: 'available-tracks'
          });

          announcedOutgoingStreamIds = availableOutgoingStreamIds;
        } else if (requestAvailableTracks) {
          this.log(`%crequesting tracks available to watch%c`, ...LOG_HIGHLIGHT_STYLE);

          port.postMessage({
            type: 'request-available-tracks'
          });

          requestAvailableTracks = false;
        } else if ((messages['request-available-tracks'] || []).length) {
          messages['request-available-tracks'].shift();

          announcedOutgoingStreamIds = null;

          this.log(`received %crequest for tracks available to watch%c`, ...LOG_HIGHLIGHT_STYLE);
        } else if ((messages['available-tracks'] || []).length) {
          availableIncomingStreams = messages['available-tracks'].shift();

          this.log(
            `received %c${Object.keys(availableIncomingStreams).length}%c peer streams %cavailable to watch`,
            LOG_HIGHLIGHT_STYLE,
            '',
            LOG_HIGHLIGHT_STYLE,
            {
              availableIncomingStreams
            }
          );
        } else if (announceRequestedIncomingTracksNeeded) {
          this.log(`%crequesting to watch%c peer streams`, ...LOG_HIGHLIGHT_STYLE, {
            requestedIncomingTrackIds: requestedIncomingStreamIds,
            requestingIncomingTrackIds: requestingIncomingStreamIds
          });

          port.postMessage({
            payload: requestingIncomingStreamIds,
            type: 'request-tracks'
          });

          requestedIncomingStreamIds = requestingIncomingStreamIds;
        } else if ((messages['request-tracks'] || []).length) {
          requestedOutgoingStreamIds = messages['request-tracks'].shift();

          this.log(`peer is %crequesting to watch%c local tracks`, ...LOG_HIGHLIGHT_STYLE, {
            requestedOutgoingStreamIds
          });
        } else {
          if (addStreamIds.length || removeStreamIds.length) {
            this.log(
              `signalingState is not "stable" but %c${peerConnection.signalingState}%c, deferring adding tracks`,
              ...LOG_HIGHLIGHT_STYLE
            );
          }

          await this.nextDeferred.promise;
        }

        if (this.incomingStreams !== nextIncomingStreams) {
          nextIncomingStreams = filterMap(nextIncomingStreams, (_, streamId) =>
            requestingIncomingStreamIds.includes(streamId)
          );

          for (let [streamId, { kind }] of Object.entries(availableIncomingStreams)) {
            nextIncomingStreams = updateIn(nextIncomingStreams, [streamId, 'kind'], () => kind);
          }

          this.incomingStreams = nextIncomingStreams;

          this.dispatchEvent(new CustomEvent('streamschange'));
        }
      }

      this.log(`Closing loop`, { aborted: abortSignal.aborted });
    } catch (err) {
      console.error(err);
    } finally {
      const closeReason = (messages.reconnect || []).length ? 'remote' : undefined;

      this.log(`%cRTCPeerConnection CLOSED%c`, ...styleConsole('yellow', 'black'), {
        closeReason,
        peerConnection
      });

      peerConnection.close();
      subscription.close();

      this.dispatchEvent(new CustomEvent('close', { detail: closeReason }));
    }
  }
}

defineEventAttribute(StreamingPeerConnection.prototype, 'close');
defineEventAttribute(StreamingPeerConnection.prototype, 'connectionstatechange');
defineEventAttribute(StreamingPeerConnection.prototype, 'senderschange');
defineEventAttribute(StreamingPeerConnection.prototype, 'streamschange');
