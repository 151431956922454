import useContext from './useContext';

export default function useState() {
  const { peers } = useContext();

  return [
    Object.entries(peers).reduce(
      (allStates, [portId, { key, state }]) => ({ ...allStates, [portId]: { key, ...(state || {}) } }),
      {}
    )
  ];
}
