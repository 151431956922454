import { useCallback } from 'react';

import useAppDispatch from './useAppDispatch';
import useAppState from './useAppState';

export default function useSelectedVideoInputDeviceIds() {
  const dispatch = useAppDispatch();

  const { selectedVideoDeviceIds: selectedVideoInputDeviceIds } = useAppState();
  const setSelectedVideoInputDeviceIds = useCallback(
    value => dispatch({ payload: value, type: 'SET_SELECTED_VIDEO_INPUT_DEVICE_IDS' }),
    [dispatch]
  );

  return [selectedVideoInputDeviceIds, setSelectedVideoInputDeviceIds];
}
