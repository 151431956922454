import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import updateIn from 'simple-update-in';

import Context from './Context';
import createMediaStreamForActionCam from '../../systems/createMediaStreamForActionCam';
import useObserveEvent from '../useObserveEvent';

const { mediaDevices } = navigator;

const DEFAULT_CREATE_DEVICE_SELECTOR = () => () => true;
const DEFAULT_CREATE_STREAM_SELECTOR = () => () => false;

const SONY_ACTIONCAM_LIVE_VIEW_DEVICE_ID = 'actioncamliveview';

const SONY_ACTIONCAM_LIVE_VIEW_DEVICE = {
  deviceId: SONY_ACTIONCAM_LIVE_VIEW_DEVICE_ID,
  groupId: SONY_ACTIONCAM_LIVE_VIEW_DEVICE_ID,
  kind: 'videoinput',
  label: 'Sony ActionCam'
};

const PSEUDO_DEVICES = [
  {
    ...SONY_ACTIONCAM_LIVE_VIEW_DEVICE,
    deviceId: 'https://192.168.1.41:7001/liveview',
    label: 'Sony ActionCam'
  }
  // {
  //   ...SONY_ACTIONCAM_LIVE_VIEW_DEVICE,
  //   deviceId: 'https://bd0ed0d1451a.ngrok.io/liveview',
  //   label: 'Sony ActionCam Remote'
  // }
];

const DeviceStream = ({ deviceId, groupId, kind, onStart }) => {
  const onStartRef = useRef();

  onStartRef.current = onStart;

  useEffect(() => {
    const abortController = new AbortController();

    (async function (signal) {
      let stream;

      if (groupId === SONY_ACTIONCAM_LIVE_VIEW_DEVICE_ID) {
        stream = createMediaStreamForActionCam({ signal, url: deviceId });
      } else {
        stream = await mediaDevices.getUserMedia({
          [kind === 'audioinput' ? 'audio' : 'video']: {
            deviceId,
            groupId,
            ...(kind === 'audio'
              ? { autoGainControl: false, channelCount: 1, latency: 0, noiseSuppression: false, sampleRate: 22100 }
              : {})
          }
        });
      }

      signal.addEventListener('abort', () => {
        for (let track of stream.getTracks()) {
          track.stop();
          stream.removeTrack(track);
        }
      });

      !signal.aborted && onStartRef.current({ deviceId, groupId, stream });
    })(abortController.signal);

    return () => abortController.abort();
  }, [deviceId, groupId, kind, onStartRef]);

  return false;
};

const DeviceCaptureProvider = ({
  children,
  createDeviceSelector = DEFAULT_CREATE_DEVICE_SELECTOR,
  createStreamSelector = DEFAULT_CREATE_STREAM_SELECTOR
}) => {
  const [devices, setDevices] = useState([...PSEUDO_DEVICES]);

  const handleDeviceChange = useCallback(async () => {
    if (mediaDevices) {
      const abortController = new AbortController();

      (async function (signal) {
        const nextDevices = (await mediaDevices.enumerateDevices()).filter(createDeviceSelector());

        !signal.aborted && setDevices([...nextDevices, ...PSEUDO_DEVICES]);
      })(abortController.signal);

      return () => abortController.abort();
    }
  }, [createDeviceSelector, setDevices]);

  useEffect(handleDeviceChange, [handleDeviceChange]);
  useObserveEvent(mediaDevices, 'devicechange', handleDeviceChange);

  const streamingDevices = useMemo(() => devices.filter(createStreamSelector()), [createStreamSelector, devices]);

  const [startedStreams, setStartedStreams] = useState([]);

  const handleStreamStart = useCallback(
    ({ deviceId, stream }) => {
      if (stream.active && stream.getTracks().length) {
        const { groupId, label } = devices.find(device => device.deviceId === deviceId);

        setStartedStreams([...startedStreams, { deviceId, groupId, label, stream }]);
      }
    },
    [devices, setStartedStreams, startedStreams]
  );

  useEffect(() => {
    const nextStartedStreams = updateIn(startedStreams, [
      ({ deviceId }) => !streamingDevices.find(streamingDevice => streamingDevice.deviceId === deviceId)
    ]);

    if (nextStartedStreams !== startedStreams) {
      setStartedStreams(nextStartedStreams);
    }
  }, [setStartedStreams, startedStreams, streamingDevices]);

  // const handleInactive = useCallback(
  //   () => setStreams(streams.filter(({ stream }) => stream.active && stream.getTracks().length)),
  //   [streams, setStreams]
  // );

  // // We should stop/remove the stream when it is gone from enumerateDevices(). This is for Safari.
  // // Also, we should stop/remove when the streamSelector is changed and no longer return that stream.

  // useObserveEvent(
  //   streams.map(({ stream }) => stream),
  //   ['inactive', 'removetrack'],
  //   handleInactive
  // );

  // const streams = useMemo(
  //   () =>
  //     startedStreams.filter(({ deviceId }) =>
  //       streamingDevices.find(streamingDevice => deviceId === streamingDevice.deviceId)
  //     ),
  //   [startedStreams, streamingDevices]
  // );

  const context = useMemo(
    () => ({
      devices,
      streams: startedStreams
    }),
    [devices, startedStreams]
  );

  // console.log('DeviceCaptureProvider', { devices, streamingDevices, startedStreams, streams });

  return (
    <Context.Provider value={context}>
      {streamingDevices.map(({ deviceId, groupId, kind }) => (
        <DeviceStream deviceId={deviceId} groupId={groupId} key={deviceId} kind={kind} onStart={handleStreamStart} />
      ))}
      {children}
    </Context.Provider>
  );
};

export default DeviceCaptureProvider;
