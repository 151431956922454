import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';

import './PeerVideo2.css';

import IconButton from './IconButton';
import IconToggleButton2 from './IconToggleButton2';
import useMutedVideoStreamIds from '../hooks/app/useMutedVideoStreamIds';
import useMutePeerAudio from '../hooks/app/useMutePeerAudio';
import useMutePeerVideoStream from '../hooks/app/useMutePeerVideo';
import usePeerConnectionReconnect from '../hooks/PeerConnection/useReconnect';
import usePeerConnectionState from '../hooks/PeerConnection/useState';
import usePeerIdsWithMutedAudio from '../hooks/app/usePeerIdsWithMutedAudio';
import useUnmutePeerAudio from '../hooks/app/useUnmutePeerAudio';
import useUnmutePeerVideoStream from '../hooks/app/useUnmutePeerVideo';
import VideoBox from './VideoBox';

const PeerVideo = ({ className, hasAudioTrack, label, peerId, srcObject, streamId }) => {
  const [{ [peerId]: { state: peerConnectionState } = {} }] = usePeerConnectionState();
  const [mutedPeerIds] = usePeerIdsWithMutedAudio();
  const [mutedVideoStreamIds] = useMutedVideoStreamIds();
  const [pictureInPicture, setPictureInPicture] = useState(false);
  const mutePeerAudio = useMutePeerAudio();
  const mutePeerVideoStream = useMutePeerVideoStream();
  const reconnect = usePeerConnectionReconnect();
  const unmutePeerAudio = useUnmutePeerAudio();
  const unmutePeerVideoStream = useUnmutePeerVideoStream();
  const videoElementRef = useRef();

  const handleHideVideoClick = useCallback(() => mutePeerVideoStream(streamId), [mutePeerVideoStream, streamId]);
  const handleMuteAudioClick = useCallback(() => mutePeerAudio(peerId), [mutePeerAudio, peerId]);
  const handleRestartClick = useCallback(() => reconnect(peerId), [peerId, reconnect]);
  const handleShowVideoClick = useCallback(() => unmutePeerVideoStream(streamId), [streamId, unmutePeerVideoStream]);
  const handleUnmuteAudioClick = useCallback(() => unmutePeerAudio(peerId), [peerId, unmutePeerAudio]);

  useEffect(() => {
    const interval = setInterval(() => {
      const { pictureInPictureElement } = document;

      const nextPictureInPicture = pictureInPictureElement && pictureInPictureElement === videoElementRef.current;

      if (pictureInPicture !== nextPictureInPicture) {
        setPictureInPicture(nextPictureInPicture);
      }
    }, 500);

    return () => clearInterval(interval);
  }, [videoElementRef, pictureInPicture, setPictureInPicture]);

  const handlePictureInPictureChange = useCallback(async () => {
    if (document.pictureInPictureElement === videoElementRef.current) {
      try {
        document.exitPictureInPicture && document.exitPictureInPicture();
      } catch (err) {}

      setPictureInPicture(false);
    } else {
      const { current } = videoElementRef;

      try {
        await (current && current.requestPictureInPicture());
      } catch (err) {}

      setPictureInPicture(true);
    }
  }, [videoElementRef, setPictureInPicture]);

  const handleEnterPictureInPicture = useCallback(() => setPictureInPicture(true), [setPictureInPicture]);
  const handleLeavePictureInPicture = useCallback(() => setPictureInPicture(false), [setPictureInPicture]);

  const hideVideo = mutedVideoStreamIds.includes(streamId);
  const muted = mutedPeerIds.includes(peerId);

  const leftStatusIcons = useMemo(() => [{ icon: 'Contact', label, mode: 'emphasis' }], [label]);

  const rightStatusIcons = useMemo(
    () => [
      ...(!!peerConnectionState && peerConnectionState.connectionState !== 'connected'
        ? [
            {
              icon: 'DisconnectVirtualMachine',
              mode: 'warning',
              title: peerConnectionState.connectionState
            }
          ]
        : []),
      ...(hideVideo
        ? [
            {
              icon: 'Hide3',
              mode: 'warning',
              title: 'Unmute to view'
            }
          ]
        : []),
      ...(hasAudioTrack
        ? muted
          ? [
              {
                icon: 'VolumeDisabled',
                mode: 'warning',
                title: 'Unmute to hear'
              }
            ]
          : []
        : [
            {
              icon: 'MicOff2',
              mode: 'emphasis',
              title: 'Peer has no microphone'
            }
          ])
    ],
    [hasAudioTrack, hideVideo, muted, peerConnectionState]
  );

  return (
    <VideoBox
      className={classNames('peer-video', className)}
      onEnterPictureInPicture={handleEnterPictureInPicture}
      onLeavePictureInPicture={handleLeavePictureInPicture}
      leftStatusIcons={leftStatusIcons}
      rightStatusIcons={rightStatusIcons}
      splashIcon={hideVideo ? 'Hide3' : ''}
      srcObject={!hideVideo && srcObject}
      videoElementRef={videoElementRef}
    >
      <div className="peer-video__bottom-bar">
        <div className="peer-video__bottom-bar-left">
          <IconButton
            className="peer-video__button"
            icon="PlugDisconnected"
            onClick={handleRestartClick}
            title="Reconnect"
          />
        </div>
        <div className="peer-video__bottom-bar-center">
          {!!hasAudioTrack && (
            <IconToggleButton2
              checked={muted}
              checkedIcon="VolumeDisabled"
              className="peer-video__button"
              icon="Volume3"
              mode={muted ? 'warning' : ''}
              onChange={muted ? handleUnmuteAudioClick : handleMuteAudioClick}
              title={muted ? 'Unmute' : 'Mute'}
            />
          )}
          <IconToggleButton2
            checked={hideVideo}
            checkedIcon="Hide3"
            className="peer-video__button"
            icon="View"
            mode={hideVideo ? 'warning' : ''}
            onChange={hideVideo ? handleShowVideoClick : handleHideVideoClick}
          />
        </div>
        <div className="peer-video__bottom-bar-right">
          {!!document.pictureInPictureEnabled && (
            <IconToggleButton2
              checked={!hideVideo && !!srcObject && pictureInPicture}
              className="peer-video__button"
              checkedIcon="MiniContract"
              disabled={hideVideo || !srcObject}
              icon="OpenInNewWindow"
              onChange={handlePictureInPictureChange}
              title="Picture-in-Picture mode"
            />
          )}
        </div>
      </div>
    </VideoBox>
  );
};

export default PeerVideo;
