const debugFlag = {
  '<AudioSource>': false,
  '<CoreApp>': false,
  '<PeerAudioGraphProvider>': false,
  '<PeerAudioToggleButton>': false,
  '<PeerConnectionProvider>': false,
  '<PlayPeerAudio>': false,
  '<SignInScreen>': false,
  '<SplashScreen>': false,
  '<VideoGrid>': false,
  '<VolumeControl>': false,
  'App.dispatch': false,
  Chatroom2: false,
  outputNode: false,
  streamingPeerConnection: false,
  useConnectNodeEffect: false
};

export default debugFlag;
