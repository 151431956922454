import { useContext as useReactContext } from 'react';

import Context from './Context';

export default function useContext() {
  const context = useReactContext(Context);

  if (!context) {
    throw new Error('useContext can only be used under <DeviceCaptureProvider>');
  }

  return context;
}
