import { useContext } from 'react';

import AppStateContext from './AppStateContext';

export default function useAppState() {
  const state = useContext(AppStateContext);

  if (!state) {
    throw new Error('useAppState can only be called under <AppProvider>');
  }

  return state;
}
