import { useContext as useReactContext } from 'react';

import Context from './Context';

export default function useContext() {
  const context = useReactContext(Context);

  if (!context) {
    throw new Error('PeerAudioGraph hooks can only be used under PeerAudioGraphProvider.');
  }

  return context;
}
