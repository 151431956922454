import { useEffect } from 'react';

export default function useObserveEvent(eventTargets, names, handler, { debug = false } = {}) {
  eventTargets = eventTargets ? (Array.isArray(eventTargets) ? eventTargets : [eventTargets]) : [];
  names = names ? (Array.isArray(names) ? names : [names]) : [];

  useEffect(() => {
    if (eventTargets.length && names.length) {
      // console.log('useObserve', { eventTarget, name, handler });

      if (debug) {
        console.log(`useObserveEvent: attaching handler "${names.join(', ')}" to ${eventTargets.length} event targets`);
      }

      eventTargets.forEach(eventTarget => {
        names.forEach(name => {
          eventTarget.addEventListener(name, handler);
        });
      });

      return () => {
        if (debug) {
          console.log(
            `useObserveEvent: detaching handler "${names.join(', ')}" from ${eventTargets.length} event targets`
          );
        }

        eventTargets.forEach(eventTarget => {
          names.forEach(name => {
            eventTarget.removeEventListener(name, handler);
          });
        });
      };
    }
  }, [debug, eventTargets, handler, names]);
}
