import { useCallback, useMemo } from 'react';
import classNames from 'classnames';

import './LocalVideo2.css';

import IconButton from './IconButton';
import useChatroomUserId from '../hooks/Chatroom/useUserId';
import useDeviceCaptureStreams from '../hooks/DeviceCapture/useStreams';
import usePeerConnectionSenders from '../hooks/PeerConnection/useSenders';
import useSelectedVideoInputDeviceIds from '../hooks/app/useSelectedVideoInputDeviceIds';
import VideoBox from './VideoBox';

const LocalVideo = ({ className, srcObject }) => {
  const [localId] = useChatroomUserId();
  const [localStreams] = useDeviceCaptureStreams();
  const [selectedVideoInputDeviceIds, setSelectedVideoInputDeviceIds] = useSelectedVideoInputDeviceIds();
  const [senders] = usePeerConnectionSenders();

  const handleStopWebcamClick = useCallback(() => {
    const { deviceId } = localStreams.find(({ stream }) => stream === srcObject) || {};

    deviceId && setSelectedVideoInputDeviceIds(selectedVideoInputDeviceIds.filter(target => target !== deviceId));
  }, [localStreams, selectedVideoInputDeviceIds, setSelectedVideoInputDeviceIds, srcObject]);

  const numSenders = Object.entries(senders)
    .reduce((allSenders, [_, senders]) => [...allSenders, ...(senders || [])], [])
    .filter(sender => srcObject && sender.track === srcObject.getVideoTracks()[0]).length;

  const leftStatusIcons = useMemo(
    () => [
      {
        icon: 'Home',
        label: `${localId} (You)`,
        mode: 'emphasis'
      }
    ],
    [localId]
  );

  const rightStatusIcons = useMemo(
    () => [
      {
        icon: 'Webcam2',
        label: numSenders,
        mode: !!numSenders && 'emphasis',
        title: `Number of peers watching: ${numSenders}`
      }
    ],
    [numSenders]
  );

  return (
    <VideoBox
      className={classNames('local-video', className)}
      leftStatusIcons={leftStatusIcons}
      rightStatusIcons={rightStatusIcons}
      srcObject={srcObject}
    >
      <div className="local-video__bottom-bar-center">
        <IconButton className="local-video__button" icon="Webcam2Off" mode="warning" onClick={handleStopWebcamClick} />
      </div>
    </VideoBox>
  );
};

export default LocalVideo;
