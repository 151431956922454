import { useCallback } from 'react';

import useAppDispatch from './useAppDispatch';
import useAppState from './useAppState';

export default function useOutputVolume() {
  const { outputVolume } = useAppState();
  const dispatch = useAppDispatch();

  const setOutputVolume = useCallback(value => dispatch({ payload: value, type: 'SET_OUTPUT_VOLUME' }), [dispatch]);

  return [outputVolume, setOutputVolume];
}
