import { useCallback, useEffect, useMemo, useState } from 'react';
import AbortController from 'abort-controller';

import Context from './Context';
import useLazyRef from '../useLazyRef';

const { mediaDevices } = window.navigator;

const AudioOutputDevicesProvider = ({ children, defaults = false }) => {
  const abortControllerRef = useLazyRef(() => new AbortController());
  const [audioOutputDevices, setAudioOutputDevices] = useState([]);

  useEffect(() => () => abortControllerRef.current.abort(), [abortControllerRef]);

  const handleDeviceChange = useCallback(async () => {
    const devices = mediaDevices ? await mediaDevices.enumerateDevices() : [];

    if (abortControllerRef.current.signal.aborted) {
      return;
    }

    let audioOutputDevices = devices.filter(({ kind }) => kind === 'audiooutput');

    if (!defaults) {
      audioOutputDevices = audioOutputDevices.filter(
        ({ deviceId }) => deviceId !== 'default' && deviceId !== 'communications'
      );
    }

    setAudioOutputDevices(audioOutputDevices);
  }, [abortControllerRef, defaults, setAudioOutputDevices]);

  useEffect(() => {
    if (mediaDevices) {
      mediaDevices.addEventListener('devicechange', handleDeviceChange);
      handleDeviceChange();

      return () => {
        mediaDevices.removeEventListener('devicechange', handleDeviceChange);
        setAudioOutputDevices([]);
      };
    }
  }, [handleDeviceChange, setAudioOutputDevices]);

  const context = useMemo(
    () => ({
      audioOutputDevices
    }),
    [audioOutputDevices]
  );

  return <Context.Provider value={context}>{children}</Context.Provider>;
};

export default AudioOutputDevicesProvider;
