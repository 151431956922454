import { useCallback, useContext } from 'react';

import AppDispatchContext from './AppDispatchContext';
import debug from '../../debug';

const log = debug('App.dispatch');

export default function useAppDispatch() {
  const dispatch = useContext(AppDispatchContext);

  if (!dispatch) {
    throw new Error('useAppDispatch can only be called under <AppProvider>');
  }

  return useCallback(value => {
    log(`Dispatching action of type "${value.type}"`, value);

    dispatch(value);
  }, [dispatch]);
}
