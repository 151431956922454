import { useCallback, useRef, useState } from 'react';

import useObserveAnalyser from '../hooks/useObserveAnalyser';

const MIN_VALUE_THRESHOLD = 1 / 128;
const VALUE_THRESHOLD = 0.1;

const SoundDetector = ({ analyser, children }) => {
  const [soundDetected, setSoundDetected] = useState(undefined);
  const soundDetectedRef = useRef(soundDetected);

  soundDetectedRef.current = soundDetected;

  const handleSoundLevel = useCallback(
    volume => {
      const nextValue =
        volume > VALUE_THRESHOLD ? 1 : volume > VALUE_THRESHOLD / 2 ? 0.5 : volume > MIN_VALUE_THRESHOLD ? 0.1 : 0;

      if (nextValue !== soundDetectedRef.current) {
        setSoundDetected(nextValue);
      }
    },
    [setSoundDetected, soundDetectedRef]
  );

  useObserveAnalyser(analyser, handleSoundLevel, { framePerSecond: 10 });

  return children(soundDetected);
};

export default SoundDetector;
