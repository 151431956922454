import { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import './SideBar.css';

import IconButton from './IconButton';
import IconToggleButton2 from './IconToggleButton2';
import InputList from './InputList';
import PeerAudioToggleButton from './PeerAudioToggleButton';
import useLowDataMode from '../hooks/app/useLowDataMode';
import usePeerConnectionState from '../hooks/PeerConnection/useState';
import useSetFlow from '../hooks/app/useFlow';

const SideBar = ({ children, className }) => {
  const [, setFlow] = useSetFlow();
  const [expanded, setExpanded] = useState(true);
  const [lowDataMode, setLowDataMode] = useLowDataMode();
  const [peerStates] = usePeerConnectionState();

  const peerIds = useMemo(() => Object.keys(peerStates), [peerStates]);

  const handleLowDataModeChange = useCallback(({ target: { checked } }) => setLowDataMode(checked), [setLowDataMode]);

  const handleRefresh = useCallback(() => setFlow(''), [setFlow]);
  const handleSettingsChange = useCallback(({ target: { checked } }) => setExpanded(checked), [setExpanded]);

  useEffect(() => {
    if (expanded) {
      const timeout = setTimeout(() => setExpanded(false), 30000);

      return () => clearTimeout(timeout);
    }
  }, [expanded]);

  return (
    <div className={classNames('side-bar', { 'side-bar--expanded': expanded }, className)}>
      <div className="side-bar__box">
        <div className="side-bar__main">
          <div className="side-bar__rest">
            {peerIds.map(peerId => (
              <PeerAudioToggleButton className="side-bar__peer" key={peerId} peerId={peerId} />
            ))}
          </div>
          <div className="side-bar__stay">
            {children}
            <div className="side-bar__divider" />
            <IconButton icon="Refresh" onClick={handleRefresh} />
            <IconToggleButton2
              checked={lowDataMode}
              icon="Savings"
              onChange={handleLowDataModeChange}
              title="Enable data saving mode"
            />
            <IconToggleButton2
              checked={expanded}
              icon={expanded ? 'DoubleChevronRight' : 'DoubleChevronLeft'}
              onChange={handleSettingsChange}
            />
          </div>
        </div>
        <div className="side-bar__aux">
          <InputList />
        </div>
      </div>
    </div>
  );
};

export default SideBar;
