import { useEffect, useMemo } from 'react';

import debug from '../../debug';
import useGainNode from './useGainNode';

const log = debug('useConnectNodeEffect');

export default function useConnectNodeEffect(node) {
  const [gainNode] = useGainNode();

  const sourceGainNode = useMemo(() => {
    const sourceGainNode = node.context.createGain();

    node.connect(sourceGainNode);

    return sourceGainNode;
  }, [node]);

  useEffect(() => {
    log('Connecting node to destination');

    sourceGainNode.connect(gainNode);

    return () => {
      log('Disconnecting node from destination');

      try {
        sourceGainNode.gain.setValueAtTime(0, 0);

        setTimeout(() => sourceGainNode.disconnect(gainNode), 100);
      } catch (err) {}
    };
  }, [gainNode, node, sourceGainNode]);
}
