import { useCallback } from 'react';

import useAppDispatch from './useAppDispatch';
import useAppState from './useAppState';

export default function useSelectedAudioInputDeviceId() {
  const dispatch = useAppDispatch();

  const { selectedAudioOutputDeviceId } = useAppState();
  const setSelectedAudioOutputDeviceId = useCallback(
    value => dispatch({ payload: value, type: 'SET_SELECTED_AUDIO_OUTPUT_DEVICE_ID' }),
    [dispatch]
  );

  return [selectedAudioOutputDeviceId, setSelectedAudioOutputDeviceId];
}
