import { fromByteArray, toByteArray } from 'base64-js';
import { useCallback, useEffect, useState } from 'react';
import updateIn from 'simple-update-in';

import './RegisterScreen.css';
import BigButtonScreen from './BigButtonScreen';
import IconButton from './ui/IconButton';
import useLazyRef from './hooks/useLazyRef';

const { AuthenticatorAttestationResponse } = window;

async function fetchPreregister(inviteCode, signal) {
  const res = await fetch('/api/preregister', {
    body: JSON.stringify({ inviteCode }),
    headers: { 'content-type': 'application/json' },
    method: 'POST',
    signal
  });

  if (signal.aborted) {
    return;
  } else if (!res.ok) {
    throw new Error('invalid invite code');
  }

  let preregister = await res.json();

  if (signal.aborted) {
    return;
  }

  preregister = updateIn(preregister, ['options', 'publicKey'], ({ base64Challenge, ...publicKey }) => ({
    ...publicKey,
    challenge: toByteArray(base64Challenge)
  }));

  preregister = updateIn(preregister, ['options', 'publicKey', 'user'], ({ base64ID, ...user }) => ({
    ...user,
    id: toByteArray(base64ID)
  }));

  return preregister;
}

const RegisterApp = ({ onClose }) => {
  const abortControllerRef = useLazyRef(() => new AbortController());
  const [busy, setBusy] = useState();
  const [error, setError] = useState();
  const [inviteCode, setInviteCode] = useState('');

  useEffect(() => () => abortControllerRef.current.abort(), [abortControllerRef]);

  const handleSubmit = useCallback(
    async event => {
      event.preventDefault();

      setBusy(true);
      setError(false);

      try {
        const aborted = () => abortControllerRef.current.signal.aborted;

        // console.log('Submit', { aborted: aborted(), inviteCode });

        let preregister;

        try {
          preregister = await fetchPreregister(inviteCode, abortControllerRef.current.signal);
        } catch (err) {
          setError(true);

          throw err;
        }

        if (aborted()) {
          return;
        }

        const credentialInfo = await navigator.credentials.create(preregister.options);

        if (aborted()) {
          return;
        }

        // console.log(credentialInfo);

        const { response } = credentialInfo;

        if (!(response instanceof AuthenticatorAttestationResponse)) {
          throw new Error('invalid credential response type');
        }

        const clientDataJSON = new TextDecoder('utf-8').decode(response.clientDataJSON);
        const clientData = JSON.parse(clientDataJSON);

        const challengeFromResponse = clientData.challenge.replace(/-/gu, '+').replace(/_/gu, '/');
        const challenge = fromByteArray(preregister.options.publicKey.challenge).replace(/=*$/u, '');

        if (clientData.type !== 'webauthn.create' || challengeFromResponse !== challenge) {
          throw new Error('invalid credential response');
        }

        const res = await fetch('/api/register', {
          body: JSON.stringify({
            response: {
              base64AttestationObject: fromByteArray(new Uint8Array(response.attestationObject)),
              base64ClientDataJSON: fromByteArray(new Uint8Array(response.clientDataJSON))
            }
          }),
          headers: {
            'content-type': 'application/json'
          },
          method: 'POST'
        });

        if (aborted()) {
          return;
        }

        if (!res.ok) {
          return;
        }

        const { userId } = await res.json();

        localStorage.setItem('userid', userId);

        onClose && onClose(true);
      } catch (err) {
        console.error(err);

        setBusy(false);
      }
    },
    [abortControllerRef, inviteCode, onClose, setBusy, setError]
  );

  const handleInviteCodeChange = useCallback(({ target: { value } }) => setInviteCode(value), [setInviteCode]);

  const disabled = !inviteCode || !!busy;

  return (
    <BigButtonScreen
      className="register-screen"
      disabled={disabled}
      icon={error ? 'BlockContact' : 'AddFriend'}
      label={error ? 'Invalid invite code' : 'Enter invite code'}
      mode={error ? 'warning' : ''}
      onClick={handleSubmit}
    >
      <form className="register-screen__form" disabled={disabled} onSubmit={handleSubmit}>
        <input
          autoComplete={false}
          autoFocus={true}
          className="register-screen__invite-code-input"
          disabled={!!busy}
          inputMode="tel"
          maxLength={6}
          onChange={handleInviteCodeChange}
          required={true}
          type="text"
          value={inviteCode}
        />
      </form>
      <IconButton className="register-screen__leave-button" icon="Leave" onClick={onClose} title="Back" />
    </BigButtonScreen>
  );
};

export default RegisterApp;
