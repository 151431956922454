export default function reducer(state, { payload, type }) {
  if (type === 'SET_SELECTED_AUDIO_INPUT_DEVICE_ID') {
    state = { ...state, selectedAudioDeviceId: payload };
  } else if (type === 'SET_SELECTED_VIDEO_INPUT_DEVICE_IDS') {
    state = { ...state, selectedVideoDeviceIds: payload };
  } else if (type === 'MUTE_PEER_ID') {
    const nextMutedPeerIds = new Set(state.mutedPeerIds);

    nextMutedPeerIds.add(payload);

    state = { ...state, mutedPeerIds: Array.from(nextMutedPeerIds) };
  } else if (type === 'UNMUTE_PEER_ID') {
    const nextMutedPeerIds = new Set(state.mutedPeerIds);

    nextMutedPeerIds.delete(payload);

    state = { ...state, mutedPeerIds: Array.from(nextMutedPeerIds) };
  } else if (type === 'HIDE_PEER_STREAM_ID') {
    const nextHideVideoStreamIds = new Set(state.hideVideoStreamIds);

    nextHideVideoStreamIds.add(payload);

    state = { ...state, hideVideoStreamIds: Array.from(nextHideVideoStreamIds) };
  } else if (type === 'UNHIDE_PEER_STREAM_ID') {
    const nextHideVideoStreamIds = new Set(state.hideVideoStreamIds);

    nextHideVideoStreamIds.delete(payload);

    state = { ...state, hideVideoStreamIds: Array.from(nextHideVideoStreamIds) };
  } else if (type === 'TOGGLE_LOW_DATA_MODE') {
    state = { ...state, lowDataMode: !!payload };
  } else if (type === 'TOGGLE_MUTE_MICROPHONE') {
    state = { ...state, muteMicrophone: !!payload };
  } else if (type === 'SET_SELECTED_AUDIO_OUTPUT_DEVICE_ID') {
    state = { ...state, selectedAudioOutputDeviceId: payload };
  } else if (type === 'SET_OUTPUT_VOLUME') {
    state = { ...state, outputVolume: payload };
  } else if (type === 'SET_FLOW') {
    state = { ...state, flow: payload };
  }

  return state;
}
