import { useCallback } from 'react';

import useAppDispatch from './useAppDispatch';
import useAppState from './useAppState';

export default function useFlow() {
  const dispatch = useAppDispatch();

  const { flow } = useAppState();
  const setFlow = useCallback(value => dispatch({ payload: value, type: 'SET_FLOW' }), [dispatch]);

  return [flow, setFlow];
}
