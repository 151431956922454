export default function createEventSubscription() {
  let unsubscribes = [];

  return {
    close() {
      while (unsubscribes.length) {
        unsubscribes.shift()();
      }
    },

    subscribe(eventTarget, names, listener, options) {
      Array.isArray(names) || (names = [names]);

      for (let name of names) {
        eventTarget.addEventListener(name, listener, options);
      }

      let unsubscribe = () => {
        for (let name of names) {
          eventTarget.removeEventListener(name, listener, options);
        }

        unsubscribes = unsubscribes.filter(u => u !== unsubscribe);
      };

      unsubscribes.push(unsubscribe);

      return unsubscribe;
    }
  };
}
