import { useCallback } from 'react';

import useAppDispatch from './useAppDispatch';
import useAppState from './useAppState';

export default function useLowDataMode() {
  const { lowDataMode } = useAppState();
  const dispatch = useAppDispatch();

  const setLowDataMode = useCallback(value => dispatch({ payload: !!value, type: 'TOGGLE_LOW_DATA_MODE' }), [dispatch]);

  return [lowDataMode, setLowDataMode];
}
