import { useReducer } from 'react';

import AppDispatchContext from './AppDispatchContext';
import AppStateContext from './AppStateContext';
import reducer from './reducer';

export default function AppProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, {
    hideVideoStreamIds: [],
    lowDataMode: false,
    mutedPeerIds: [],
    muteMicrophone: false,
    outputVolume: 1,
    selectedAudioDeviceId: null,
    selectedVideoDeviceIds: []
  });

  return (
    <AppDispatchContext.Provider value={dispatch}>
      <AppStateContext.Provider value={state}>{children}</AppStateContext.Provider>
    </AppDispatchContext.Provider>
  );
}
