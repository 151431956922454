import { useEffect } from 'react';
import AbortController from 'abort-controller';

export default function useAsyncEffect(fn, deps) {
  useEffect(() => {
    const abortController = new AbortController();

    let teardownFn;

    fn(abortController.signal).then(fn => {
      teardownFn = fn;
    });

    return () => {
      abortController.abort();
      teardownFn && teardownFn();
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, deps);
}
