import { useCallback } from 'react';

import useAppDispatch from './useAppDispatch';
import useAppState from './useAppState';

export default function useMuteMicrophone() {
  const { muteMicrophone } = useAppState();
  const dispatch = useAppDispatch();

  const setMuteMicrophone = useCallback(value => dispatch({ payload: !!value, type: 'TOGGLE_MUTE_MICROPHONE' }), [dispatch]);

  return [muteMicrophone, setMuteMicrophone];
}
