import classNames from 'classnames';

import './VideoBox.css';

import StatusIcon from './StatusIcon';
import VideoSource from './VideoSource';

const VideoBox = ({
  children,
  className,
  onEnterPictureInPicture,
  onLeavePictureInPicture,
  leftStatusIcons,
  rightStatusIcons,
  splashIcon,
  srcObject,
  videoElementRef
}) => {
  if (!splashIcon && !srcObject) {
    splashIcon = 'Plug';
  }

  return (
    <div className={classNames('video-box', className)}>
      {!!splashIcon && (
        <div
          className={classNames('video-box__splash', {
            'video-box__splash--no-video': !srcObject
          })}
        >
          <i className={`video-box__splash-icon video-box__splash-icon ms-Icon ms-Icon--${splashIcon}`} />
        </div>
      )}
      {!!srcObject && (
        <VideoSource
          className="video-box__video-source"
          onEnterPictureInPicture={onEnterPictureInPicture}
          onLeavePictureInPicture={onLeavePictureInPicture}
          ref={videoElementRef}
          srcObject={srcObject}
        />
      )}
      <div className="video-box__upper-right">
        {rightStatusIcons.map(({ icon, mode, label, title }, index) => (
          <StatusIcon
            className="video-box__status_icon"
            icon={icon}
            key={index}
            mode={mode}
            label={label}
            title={title}
          />
        ))}
      </div>
      <div className="video-box__upper-left">
        {leftStatusIcons.map(({ icon, mode, label, title }, index) => (
          <StatusIcon
            className="video-box__status_icon"
            icon={icon}
            key={index}
            mode={mode}
            label={label}
            title={title}
          />
        ))}
      </div>
      <div className="video-box__bottom-bar">{children}</div>
    </div>
  );
};

export default VideoBox;
