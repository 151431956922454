import { fromByteArray, toByteArray } from 'base64-js';
import { useCallback, useEffect, useState } from 'react';
import AbortController from 'abort-controller';
import updateIn from 'simple-update-in';

import './SignInScreen.css';

import BigButtonScreen from './BigButtonScreen';
import debug from './debug';
import IconButton from './ui/IconButton';
import useLazyRef from './hooks/useLazyRef';

const log = debug('<SignInScreen>');

const { AuthenticatorAssertionResponse } = window;

async function fetchPreSignIn(userId, signal) {
  const res = await fetch('/api/presignin', {
    body: userId ? JSON.stringify({ userId }) : '',
    headers: { 'content-type': 'application/json' },
    method: 'POST',
    signal
  });

  if (signal.aborted) {
    return;
  } else if (!res.ok) {
    throw new Error('failed to call /api/presignin');
  }

  let preSignIn = await res.json();

  if (signal.aborted) {
    return;
  }

  preSignIn = updateIn(preSignIn, ['options', 'publicKey'], ({ base64Challenge, ...publicKey }) => ({
    ...publicKey,
    challenge: toByteArray(base64Challenge)
  }));

  preSignIn = updateIn(
    preSignIn,
    ['options', 'publicKey', 'allowCredentials', () => true],
    ({ base64Id, ...allowCredential }) => ({
      ...allowCredential,
      id: toByteArray(base64Id)
    })
  );

  log([`Fetched pre-signin data`], { preSignIn });

  return preSignIn;
}

const SignInScreen = ({ onGuestModeClick, onRegisterClick, onSuccess }) => {
  const [busy, setBusy] = useState();
  // const [flow, setFlow] = useState('signin');
  const [showGuestModeButton, setShowGuestModeButton] = useState(true);
  const [showRegisterButton, setShowRegisterButton] = useState();
  const abortControllerRef = useLazyRef(() => new AbortController());
  // const handleRegisterScreenClose = useCallback(() => setFlow('signin'), [setFlow]);

  useEffect(() => () => abortControllerRef.current.abort(), [abortControllerRef]);

  const handleSignInClick = useCallback(async () => {
    setBusy(true);

    try {
      const aborted = () => abortControllerRef.current.signal.aborted;
      const savedUserId = localStorage.getItem('userid');
      let assertion;
      let preSignIn;

      // First try = no user ID (client-side discoverable key, a.k.a. require resident key)
      // Second try = with user ID (server-side discoverable key, a.k.a. not supporting resident key, e.g. Android)

      for (let sendUserId = false; ; sendUserId = true) {
        preSignIn = await fetchPreSignIn(sendUserId ? savedUserId : '', abortControllerRef.current.signal);

        if (aborted()) {
          return;
        }

        try {
          assertion = await navigator.credentials.get(preSignIn.options);

          break;
        } catch (err) {
          setShowGuestModeButton(true);
          setShowRegisterButton(true);

          if (!(err.message.includes('allowCredentials') && savedUserId && !sendUserId)) {
            throw err;
          }
        }
      }

      if (aborted()) {
        return;
      }

      const { response } = assertion;

      if (!(response instanceof AuthenticatorAssertionResponse)) {
        throw new Error('invalid assertion response type');
      }

      const clientDataJSON = new TextDecoder('utf-8').decode(response.clientDataJSON);
      const clientData = JSON.parse(clientDataJSON);

      const challengeFromResponse = clientData.challenge.replace(/-/gu, '+').replace(/_/gu, '/');
      const challenge = fromByteArray(preSignIn.options.publicKey.challenge).replace(/=*$/u, '');

      if (clientData.type !== 'webauthn.get' || challengeFromResponse !== challenge) {
        throw new Error('invalid assertion response');
      }

      log([`Got WebAuthn assertion response`], { response, savedUserId });

      const res = await fetch('/api/signin', {
        body: JSON.stringify({
          response: {
            base64AuthenticatorData: fromByteArray(new Uint8Array(response.authenticatorData)),
            base64ClientDataJSON: fromByteArray(new Uint8Array(response.clientDataJSON)),
            base64Signature: fromByteArray(new Uint8Array(response.signature)),
            base64UserHandle: fromByteArray(
              response.userHandle ? new Uint8Array(response.userHandle) : new TextEncoder().encode(savedUserId)
            ),
            id: assertion.id
          }
        }),
        headers: {
          'content-type': 'application/json'
        },
        method: 'POST'
      });

      if (aborted()) {
        return;
      }

      if (!res.ok) {
        throw new Error('failed to authenticate');
      }

      const authToken = await res.text();

      sessionStorage.setItem('authtoken', authToken);

      onSuccess && onSuccess();
    } catch (err) {
      console.error(err);

      setBusy(false);
    }
  }, [abortControllerRef, onSuccess, setBusy]);

  const disabled = !!busy;

  return (
    <BigButtonScreen
      className="sign-in-screen"
      disabled={disabled}
      icon="Fingerprint"
      label={busy ? 'Signing in' : 'Sign in'}
      onClick={handleSignInClick}
    >
      {showGuestModeButton && (
        <IconButton
          className="sign-in-screen__guest-mode-button"
          icon="BeerMug"
          onClick={onGuestModeClick}
          title="Guest mode"
        />
      )}
      {showRegisterButton && (
        <IconButton
          className="sign-in-screen__register-button"
          icon="AddFriend"
          onClick={onRegisterClick}
          title="Register"
        />
      )}
    </BigButtonScreen>
  );
};

export default SignInScreen;
