import EventTarget, { defineEventAttribute } from 'event-target-shim';

import debug from '../debug';
import diff from '../data/sagas/utils/diff';
import styleConsole from '../utils/styleConsole';

const log = debug('Chatroom2', 'yellow', 'black');

class ChatroomPort extends EventTarget {
  constructor(id, chatroom, polite) {
    super();

    this.chatroom = chatroom;
    this.id = id;
    this.key = id;
    this.messageEventBacklog = [];
    this.polite = polite;

    this.handleMessage = this.handleMessage.bind(this);

    chatroom.addEventListener('message', this.handleMessage);
  }

  close() {
    this.chatroom.removeEventListener('message', this.handleMessage);
  }

  handleMessage({ data, origin }) {
    log([`%c${this.id}%c handleMessage()`, ...styleConsole('orange')], {
      backlog: this.messageEventBacklog,
      data,
      origin
    });

    if (origin === this.id) {
      const event = new MessageEvent('message', { data, origin });

      if (this.messageEventBacklog) {
        this.messageEventBacklog.push(event);
      } else {
        this.dispatchEvent(event);
      }
    }
  }

  set onmessage(listener) {
    super.onmessage = listener;

    this.start();
  }

  postMessage(data) {
    log([`%c${this.id}%c postMessage()`, ...styleConsole('orange')], { data });

    this.chatroom.send(this.id, data);
  }

  restart() {
    this.key = this.id + Math.random().toString(36).substr(1, 6);
  }

  start() {
    log([`%c${this.id}%c start()`, ...styleConsole('orange')], { backlog: this.messageEventBacklog });

    if (this.messageEventBacklog) {
      for (let event of this.messageEventBacklog) {
        this.dispatchEvent(event);
      }

      this.messageEventBacklog = null;
    }
  }
}

defineEventAttribute(ChatroomPort.prototype, 'message');

export default class Chatroom extends EventTarget {
  constructor(url) {
    super();

    this.ports = {};
    this.url = url;

    this.start();
  }

  close() {
    for (let portId of Object.keys(this.ports)) {
      this.closePort(portId);
    }

    this.ports = {};
    this.webSocket && this.webSocket.close();
  }

  closePort(portId) {
    this.ports[portId].close();

    const { [portId]: _, ...nextPorts } = this.ports;

    this.ports = nextPorts;
  }

  openPort(portId) {
    this.ports = { ...this.ports, [portId]: new ChatroomPort(portId, this, portId > this.localId) };
  }

  send(portId, data) {
    if (!(portId in this.ports)) {
      log(`${portId} cannot send to a port that is not in peer list`, { data });
    }

    try {
      this.webSocket &&
        this.webSocket.send(
          JSON.stringify({
            meta: { to: portId },
            payload: data,
            type: 'SEND_DIRECT_MESSAGE'
          })
        );
    } catch (error) {
      log([`%cError%c`, ...styleConsole('red')], { error });
    }
  }

  start() {
    const webSocket = (this.webSocket = new WebSocket(this.url));

    webSocket.addEventListener('close', () => {
      log(`Web Socket closed`);

      for (let portId of Object.keys(this.ports)) {
        this.closePort(portId);
      }

      this.ports = {};
      this.webSocket = undefined;

      this.dispatchEvent(new CustomEvent('close'));

      // setTimeout(() => this.start(), 5000);
    });

    webSocket.addEventListener('message', ({ data }) => {
      const { meta, payload, type } = JSON.parse(data);

      log([`Received a message of type %c${type}%c`, ...styleConsole('purple')], [data]);

      if (type === 'SERVER/UPDATE_PEERS') {
        const localId = (this.localId = payload.userId);

        const { added, removed } = diff(
          Object.keys(this.ports),
          Object.keys(payload.peers).filter(id => id !== localId)
        );

        log([`Received new peers list, added ${added.length} ports and removed ${removed.length}`], {
          prevPorts: Object.keys(this.ports),
          nextPorts: Object.keys(payload.peers).filter(id => id !== localId)
        });

        for (let portId of removed) {
          this.closePort(portId);
        }

        for (let portId of added) {
          this.openPort(portId);
        }

        this.dispatchEvent(new CustomEvent('portschange'));
      } else if (type === 'RECEIVE_DIRECT_MESSAGE') {
        this.dispatchEvent(new MessageEvent('message', { data: payload, origin: meta.from }));
      }
    });

    webSocket.addEventListener('open', () => {
      this.ports = {};
    });
  }
}

defineEventAttribute(Chatroom.prototype, 'close');
defineEventAttribute(Chatroom.prototype, 'message');
defineEventAttribute(Chatroom.prototype, 'portschange');
