import { forwardRef, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import AbortController from 'abort-controller';

import debug from '../debug';
import useObserveEvent from '../hooks/useObserveEvent';

const log = debug('<AudioSource>');

const AudioSource = forwardRef(({ className, muted, onPause, sinkId, srcObject, volume }, refFromProp) => {
  const [removed, setRemoved] = useState();
  const refFromLocal = useRef();
  const ref = refFromProp || refFromLocal;

  const handlePlaying = useCallback(() => setRemoved(false), [setRemoved]);
  const handleRemoveTrack = useCallback(() => setRemoved(true), [setRemoved]);

  useLayoutEffect(() => {
    const { current } = ref;

    if (current && srcObject) {
      current.srcObject = srcObject;
      srcObject.addEventListener('removetrack', handleRemoveTrack);

      return () => {
        srcObject.removeEventListener('removetrack', handleRemoveTrack);
        current.srcObject = null;
      };
    }
  }, [ref, handleRemoveTrack, srcObject]);

  useLayoutEffect(() => {
    const { current } = ref;

    if (current && current.setSinkId && sinkId) {
      const abortController = new AbortController();

      (async function () {
        try {
          await current.setSinkId(sinkId);
        } catch (err) {
          console.log(
            `%caudioSource%c: Failed to set sink ID to ${sinkId}`,
            'background-color: yellow; padding: 2px;',
            '',
            { err, target: current }
          );
        }
      })();

      return () => abortController.abort();
    }
  }, [ref, sinkId]);

  useLayoutEffect(() => {
    const { current } = ref;

    if (current && typeof volume !== 'undefined') {
      current.volume = Math.max(0, Math.min(1, volume));
    }
  }, [ref, volume]);

  useEffect(() => {
    const { current } = ref;

    current.paused && log('paused', { current });

    current && current.paused && onPause && onPause();
  }, [ref, onPause]);

  const resumePlayback = useCallback(() => {
    const { current } = ref;

    try {
      current && current.paused && current.play();
    } catch (err) {}
  }, [ref]);

  useObserveEvent(window, 'click', resumePlayback);

  return (
    !removed && (
      <audio
        autoPlay={true}
        className={className}
        muted={!!muted}
        onPause={onPause}
        onPlaying={handlePlaying}
        playsInline={true}
        ref={ref}
      />
    )
  );
});

export default AudioSource;
