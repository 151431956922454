import styleConsole from './utils/styleConsole';

import debugFlag from './debugFlag';

export default function debug(topic, backgroundColor = 'green', color = 'white') {
  return (arg0, ...args) => {
    if (debugFlag[topic] !== false) {
      const lines = [];

      if (Array.isArray(arg0)) {
        lines.push(arg0);

        args.forEach(arg => {
          lines.push(Array.isArray(arg) ? arg : [arg]);
        });
      } else {
        lines.push([arg0, ...args]);
      }

      const [firstLine, ...firstArgs] = lines.shift();
      const firstLog = [`%c${topic}%c ${firstLine}`, ...styleConsole(backgroundColor, color), ...firstArgs];

      if (lines.length > 1) {
        console.groupCollapsed(...firstLog);

        lines.slice(1).forEach(line => {
          console.log(line);
        });

        console.groupEnd();
      } else {
        console.log(...firstLog);
      }
    }
  };
}
