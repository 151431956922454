import classNames from 'classnames';

import './BigButtonScreen.css';

import IconButton from './ui/IconButton';

const BigButtonScreen = ({ children, className, disabled, icon, label, mode, onClick }) => {
  return (
    <div className={classNames(className, 'big-button-screen')}>
      <IconButton className="big-button-screen__button" disabled={disabled} icon={icon} mode={mode} onClick={onClick} />
      {!!label && <div>{label}</div>}
      {children}
    </div>
  );
};

export default BigButtonScreen;
