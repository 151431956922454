import { useCallback, useEffect, useState } from 'react';
import AbortController from 'abort-controller';

import './GuestModeFlow.css';
import BigButtonScreen from './BigButtonScreen';
import IconButton from './ui/IconButton';
import useLazyRef from './hooks/useLazyRef';

async function fetchGuestSignIn(totp, signal) {
  const res = await fetch('/api/guest', {
    body: JSON.stringify({ totp }),
    headers: { 'content-type': 'application/json' },
    method: 'POST',
    signal
  });

  if (signal.aborted) {
    return;
  } else if (!res.ok) {
    throw new Error('failed to authenticate as guest');
  }

  const authToken = await res.text();

  sessionStorage.setItem('authtoken', authToken);

  return authToken;
}

const GuestModeFlow = ({ onClose, onSuccess }) => {
  const abortControllerRef = useLazyRef(() => new AbortController());

  useEffect(() => () => abortControllerRef.current.abort(), [abortControllerRef]);

  const [busy, setBusy] = useState(false);
  const [error, setError] = useState();
  const [guestCode, setGuestCode] = useState('');

  const handleGuestCodeChange = useCallback(({ target: { value } }) => setGuestCode(value), [setGuestCode]);
  const handleSubmit = useCallback(
    event => {
      event.preventDefault();

      setBusy(true);

      (async function () {
        try {
          const token = await fetchGuestSignIn(guestCode, abortControllerRef.current.signal);

          abortControllerRef.current.signal.aborted || onSuccess(token);
        } catch (err) {
          if (!abortControllerRef.current.signal.aborted) {
            setError(true);
            setBusy(false);
          }
        }
      })();
    },
    [abortControllerRef, guestCode, onSuccess, setBusy]
  );

  const disabled = !guestCode || !!busy;

  return (
    <BigButtonScreen
      className="guest-mode-flow"
      disabled={disabled}
      icon={error ? 'BlockContact' : 'BeerMug'}
      label={error ? 'Invalid guest code' : 'Enter guest code'}
      mode={error ? 'warning' : ''}
      onClick={handleSubmit}
    >
      <form className="guest-mode-flow__form" disabled={disabled} onSubmit={handleSubmit}>
        <input
          autoComplete="false"
          autoFocus={true}
          className="guest-mode-flow__guest-code-input"
          disabled={busy}
          inputMode="tel"
          maxLength={6}
          onChange={handleGuestCodeChange}
          required={true}
          type="text"
          value={guestCode}
        />
      </form>
      <IconButton className="guest-mode-flow__leave-button" icon="Leave" onClick={onClose} title="Back" />
    </BigButtonScreen>
  );
};

export default GuestModeFlow;
