const BUFFER_SIZE = 1048576;

function readUint16(array, index) {
  return (array[index] << 8) + array[index + 1];
}

function readUint24(array, index) {
  return (array[index] << 16) + (array[index + 1] << 8) + array[index + 2];
}

// function readUint32(array, index) {
//   return (array[index] << 24) + (array[index + 1] << 16) + (array[index + 2] << 8) + array[index + 3];
// }

export default function fetchLiveViewFrames(url = '/liveview') {
  const abortController = new AbortController();
  const { signal } = abortController;

  return {
    cancel: () => {
      abortController.abort();
    },
    [Symbol.asyncIterator]: async function* () {
      const res = await fetch(url, { signal });

      if (!res.ok) {
        throw new Error(`server returned ${res.status}`);
      }

      const reader = res.body.getReader();

      signal.addEventListener('abort', () => reader.cancel());

      try {
        const buffer = new Uint8Array(BUFFER_SIZE);
        let bufferSize = 0;

        // for (let i = 0; i < 1000000; i++) {
        for (;;) {
          const { done, value } = await reader.read();

          if (done) {
            break;
          }

          // console.log(value);

          try {
            buffer.set(value, bufferSize);
          } catch (err) {
            console.log({ buffer, bufferSize, value });

            throw err;
          }

          bufferSize += value.length;

          while (bufferSize >= 136) {
            if (buffer[0] !== 0xff) {
              throw new Error('Cannot find start byte 0xFF', { buffer });

              // break;
            }

            const payloadType = buffer[1];
            // const sequenceNumber = readUint16(buffer, 2);
            // const timestamp = readUint32(buffer, 4);

            if (!(buffer[8] === 0x24 && buffer[9] === 0x35 && buffer[10] === 0x68 && buffer[11] === 0x79)) {
              throw new Error('Cannot find start code 0x24, 0x35, 0x68, 0x79', { buffer });

              // break;
            }

            const payloadDataSizeWithoutPadding = readUint24(buffer, 12);
            const paddingSize = buffer[15];
            const packetSize = 8 + 128 + payloadDataSizeWithoutPadding + paddingSize;

            if (bufferSize < packetSize) {
              break;
            }

            if (payloadType === 1) {
              // const imageWidth = readUint16(buffer, 16);
              // const imageHeight = readUint16(buffer, 18);
              const jpegData = buffer.slice(136, 136 + payloadDataSizeWithoutPadding);

              yield jpegData;

              // worker.postMessage(jpegData);
            } else if (payloadType === 2) {
              // const frameInformationDataVersion = readUint16(buffer, 16);
              const frameCount = readUint16(buffer, 18);
              const singleFrameDataSize = readUint16(buffer, 20);
              const frameInformation = [];

              for (let index = 0; index < frameCount; index++) {
                const offset = 136 + index * singleFrameDataSize;

                frameInformation.push({
                  additionalStatus: buffer[offset + 10],
                  bottomRight: readUint16(buffer, offset + 4),
                  category: buffer[offset + 8],
                  status: buffer[offset + 9],
                  topLeft: readUint16(buffer, offset)
                });
              }

              console.log(frameInformation);
            }

            buffer.copyWithin(0, packetSize);
            bufferSize -= packetSize;
          }
        }
      } finally {
        reader.cancel();
      }
    }
  };
}
