import { useCallback } from 'react';
import classNames from 'classnames';

import './PeerAudioToggleButton.css';

import debug from '../debug';
import IconButton from './IconButton';
import IconToggleButton2 from './IconToggleButton2';
import SoundDetector from './SoundDetector4';
import styleConsole from '../utils/styleConsole';
import useAudioContext from '../hooks/AudioOutputNode/useAudioContext';
import useMutePeerAudio from '../hooks/app/useMutePeerAudio';
import usePeerAudioGraphs from '../hooks/PeerAudioGraph/useGraphs';
import usePeerConnectionReconnect from '../hooks/PeerConnection/useReconnect';
import usePeerIdsWithMutedAudio from '../hooks/app/usePeerIdsWithMutedAudio';
import useUnmutePeerAudio from '../hooks/app/useUnmutePeerAudio';

const log = debug('<PeerAudioToggleButton>');

const PeerAudioToggleButton = ({ className, peerId }) => {
  const [audioContext] = useAudioContext();
  const [graphs] = usePeerAudioGraphs();
  const [peerIdsWithMutedAudio] = usePeerIdsWithMutedAudio();
  const reconnect = usePeerConnectionReconnect();

  const mutePeerAudio = useMutePeerAudio();
  const unmutePeerAudio = useUnmutePeerAudio();

  const handleMuteChange = useCallback(
    ({ target: { checked } }) => (checked ? mutePeerAudio(peerId) : unmutePeerAudio(peerId)),
    [mutePeerAudio, peerId, unmutePeerAudio]
  );

  const handleReconnectClick = useCallback(() => reconnect(peerId), [peerId, reconnect]);

  const { analyserNode } = graphs.find(target => target.peerId === peerId) || {};

  log([`%c${peerId}%c Render, hasAnalyserNode = ${!!analyserNode}`, ...styleConsole('purple')]);

  const muted = peerIdsWithMutedAudio.includes(peerId) || audioContext.state !== 'running';

  return (
    <div className={classNames('peer-badge', className)}>
      <div className="peer-badge__label">{peerId}</div>
      <div className="peer-badge__buttons">
        <SoundDetector analyser={!muted && analyserNode}>
          {sound => (
            <IconToggleButton2
              checked={!!analyserNode && muted}
              className="peer-badge__toggle-button"
              disabled={!analyserNode}
              icon={
                analyserNode
                  ? muted
                    ? 'VolumeDisabled'
                    : sound === 1
                    ? 'Volume3'
                    : sound >= 0.5
                    ? 'Volume2'
                    : sound
                    ? 'Volume1'
                    : 'Volume0'
                  : 'SpeechOff'
              }
              mode={analyserNode && muted ? 'warning' : ''}
              onChange={handleMuteChange}
            />
          )}
        </SoundDetector>
        <IconButton icon="PlugDisconnected" onClick={handleReconnectClick} />
      </div>
    </div>
  );
};

export default PeerAudioToggleButton;
