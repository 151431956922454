import { useEffect } from 'react';
import AbortController from 'abort-controller';

const FFT_SIZE = 4096;
const VALUE_DECAY_PER_INTERVAL = 1.5;

function animateAtFPS(callback, framePerSecond, signal) {
  const millisecondsBetweenFrames = 1000 / framePerSecond;
  let lastTimestamp = 0;

  const frameCallback = timestamp => {
    if (signal.aborted) {
      return;
    }

    if (!lastTimestamp || timestamp - lastTimestamp > millisecondsBetweenFrames) {
      callback();
      lastTimestamp = timestamp;
    }

    requestAnimationFrame(frameCallback);
  };

  signal.aborted || requestAnimationFrame(frameCallback);
}

export default function useObserveAnalyser(analyser, callback, { framePerSecond } = { framePerSecond: 30 }) {
  useEffect(() => {
    if (!analyser) {
      return callback(undefined);
    }

    const abortController = new AbortController();

    analyser.fftSize = FFT_SIZE;

    const bufferLength = analyser.frequencyBinCount;
    const buffer = new Uint8Array(bufferLength);

    let lastMaxVolume = 0;

    animateAtFPS(
      () => {
        analyser.getByteTimeDomainData(buffer);

        let maxVolume = [].reduce.call(
          buffer,
          (maxVolume, data) => Math.max(maxVolume, Math.abs((data - 128) / 128)),
          0
        );

        if (maxVolume < lastMaxVolume) {
          maxVolume = Math.pow(lastMaxVolume - 0.01, VALUE_DECAY_PER_INTERVAL);
        }

        lastMaxVolume = maxVolume;

        callback(maxVolume);
      },
      framePerSecond,
      abortController.signal
    );

    return () => abortController.abort();
  }, [analyser, callback, framePerSecond]);

  return false;
}
