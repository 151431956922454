import { useEffect, useMemo, useRef } from 'react';
import updateIn from 'simple-update-in';

import diff from '../../data/sagas/utils/diff';

import Context from './Context';
import debug from '../../debug';
import styleConsole from '../../utils/styleConsole';
import usePeerConnectionStreams from '../PeerConnection/useStreams';

const log = debug('<PeerAudioGraphProvider>');

const PeerAudioGraphProvider = ({ audioContext, children }) => {
  const [peerStreams] = usePeerConnectionStreams();
  const flatPeerStreamsRef = useRef({});

  const flatPeerStreams = useMemo(() => {
    let flatPeerStreams = {};

    for (let [peerId, streams] of Object.entries(peerStreams)) {
      for (let { kind, stream } of Object.values(streams)) {
        if (stream && kind === 'audio') {
          flatPeerStreams[[peerId, stream.id].join('|')] = { peerId, stream };
        }
      }
    }

    return flatPeerStreams;
  }, [peerStreams]);

  let { current: nextFlatPeerStreams } = flatPeerStreamsRef;
  const { added, removed } = diff(Object.keys(nextFlatPeerStreams), Object.keys(flatPeerStreams));

  for (let key of removed) {
    nextFlatPeerStreams[key].teardown();
    nextFlatPeerStreams = updateIn(nextFlatPeerStreams, [key]);
  }

  for (let key of added) {
    const { peerId, stream, track } = flatPeerStreams[key];

    const analyserNode = audioContext.createAnalyser();

    // Although the gainNode here looks useless, Safari does not support disconnect(node) from a particular node, disconnect always disconnect everything.
    // If we don't have this gainNode, the analyserNode will get disconnect altogether.
    // const gainNode = audioContext.createGain();
    const sourceNode = audioContext.createMediaStreamSource(stream);

    sourceNode.connect(analyserNode);
    // sourceNode.connect(gainNode);

    nextFlatPeerStreams = updateIn(nextFlatPeerStreams, [key], () => ({
      analyserNode,
      // finalNode: gainNode,
      finalNode: sourceNode,
      // gainNode,
      key: [peerId, stream.id].join('|'),
      peerId,
      sourceNode,
      stream,
      track,
      teardown: () => {
        // gainNode.disconnect();
        analyserNode.disconnect();
        sourceNode.disconnect();
      }
    }));
  }

  flatPeerStreamsRef.current = nextFlatPeerStreams;

  useEffect(() => {
    for (let { teardown } of Object.values(flatPeerStreamsRef.current)) {
      teardown();
    }
  }, [flatPeerStreamsRef]);

  const graphs = Object.values(nextFlatPeerStreams);

  const context = useMemo(
    () => ({
      graphs
    }),
    [graphs]
  );

  log(`Rendered %c${graphs.length}%c graphs`, ...styleConsole('purple'), { graphs });

  return <Context.Provider value={context}>{children}</Context.Provider>;
};

export default PeerAudioGraphProvider;
