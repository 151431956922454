import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import updateIn from 'simple-update-in';

import Context from './Context';
import createEventSubscription from '../../utils/createEventSubscription';
import debug from '../../debug';
import diff from '../../data/sagas/utils/diff';
import StreamingPeerConnection from '../../systems/StreamingPeerConnection';
import styleConsole from '../../utils/styleConsole';
import unique from '../../utils/unique';
import useForceRender from '../useForceRender';

const log = debug('<PeerConnectionProvider>');

const LOG_BADGE_STYLE = styleConsole('green');
const LOG_VAR_STYLE = styleConsole('purple');

const SERVER_OPTIONS = { iceServers: [{ urls: 'stun:stun.l.google.com:19302' }] };

const PeerConnectionProvider = ({
  audioBitrate,
  children,
  ports,
  receiveTrackSelector,
  sendTracks = [],
  videoBitrate
}) => {
  const [peerStates, setPeerStates] = useState({});
  const forceRender = useForceRender();
  const portsRef = useRef();

  portsRef.current = ports;

  const portKeysRef = useRef({});

  const rotatePortKeyRef = useRef(portId => {
    if (portId in portKeysRef.current) {
      portKeysRef.current = { ...portKeysRef.current, [portId]: `${portId}#${unique()}` };

      forceRender();
    }
  });

  const contextRef = useRef({
    peers: {},
    reconnect: portId => {
      rotatePortKeyRef.current(portId);
    }
  });

  const { added, removed } = diff(Object.keys(portKeysRef.current), Object.keys(ports));

  for (let portId of removed) {
    const { [portId]: _, ...nextPortKeys } = portKeysRef.current;

    portKeysRef.current = nextPortKeys;

    delete contextRef.current.peers[portId];
  }

  for (let portId of added) {
    portKeysRef.current = { ...portKeysRef.current, [portId]: `${portId}#0` };
  }

  const peerSendersRef = useRef({});
  const peerStreamsRef = useRef({});

  const handleSendersChange = useCallback(
    (portId, nextSenders) => {
      peerSendersRef.current = updateIn(peerSendersRef.current, [portId], () => nextSenders);
      contextRef.current = updateIn(contextRef.current, ['peers', portId, 'senders'], () => nextSenders);

      forceRender();
    },
    [forceRender, peerSendersRef]
  );

  const handleStreamsChange = useCallback(
    (portId, nextStreams) => {
      log(
        `%c${portId}:`,
        'color: green',
        `Updating incoming peer streams with ${Object.keys(nextStreams).length} streams`,
        nextStreams
      );

      peerStreamsRef.current = updateIn(peerStreamsRef.current, [portId], () => nextStreams);
      contextRef.current = updateIn(contextRef.current, ['peers', portId, 'streams'], () => nextStreams);

      forceRender();
    },
    [forceRender, peerStreamsRef]
  );

  const handleStateChange = useCallback(
    (portId, nextState) => {
      setPeerStates(updateIn(peerStates, [portId, 'state'], () => nextState));
      contextRef.current = updateIn(contextRef.current, ['peers', portId, 'state'], () => nextState);

      const { connectionState, iceConnectionState } = nextState;

      if (
        // connectionState === 'disconnected' || // It seems it's routine for Safari to send "disconnect".
        connectionState === 'failed' ||
        // iceConnectionState === 'disconnected' ||
        iceConnectionState === 'failed'
      ) {
        rotatePortKeyRef.current(portId);
      }
    },
    [peerStates, rotatePortKeyRef, setPeerStates]
  );

  const handlePortCloseRef = useRef(portId => {
    rotatePortKeyRef.current(portId);
  });

  log([`Render`], {
    audioBitrate,
    currentContext: contextRef.current,
    ports,
    videoBitrate
  });

  return (
    <Context.Provider value={contextRef.current}>
      {Object.values(ports).map(port => (
        <PeerConnection
          audioBitrate={audioBitrate}
          key={portKeysRef.current[port.id]}
          onClose={handlePortCloseRef.current}
          onSendersChange={handleSendersChange}
          onStateChange={handleStateChange}
          onStreamsChange={handleStreamsChange}
          port={port}
          receiveTrackSelector={receiveTrackSelector}
          sendTracks={sendTracks}
          videoBitrate={videoBitrate}
        />
      ))}
      {children}
    </Context.Provider>
  );
};

const PeerConnection = forwardRef(
  (
    {
      audioBitrate,
      onClose,
      onSendersChange,
      onStateChange,
      onStreamsChange,
      port,
      receiveTrackSelector,
      sendTracks,
      videoBitrate
    },
    ref
  ) => {
    const receiveTrackSelectorRef = useRef(receiveTrackSelector);
    const sendTracksRef = useRef(sendTracks);

    receiveTrackSelectorRef.current = receiveTrackSelector;
    sendTracksRef.current = sendTracks;

    let localRef = useRef();
    let connectionRef = ref || localRef;

    const onCloseRef = useRef();
    const onSendersChangeRef = useRef();
    const onStateChangeRef = useRef();
    const onStreamsChangeRef = useRef();

    onCloseRef.current = onClose;
    onSendersChangeRef.current = onSendersChange;
    onStateChangeRef.current = onStateChange;
    onStreamsChangeRef.current = onStreamsChange;

    useEffect(
      () => () => {
        onCloseRef.current = onSendersChangeRef.current = onStateChangeRef.current = onStreamsChangeRef.current = null;
      },
      []
    );

    useEffect(() => {
      const connection = (connectionRef.current = new StreamingPeerConnection(SERVER_OPTIONS, port, port.polite, {
        audioBitrate,
        videoBitrate
      }));

      connection.setAvailableTracks(sendTracksRef.current);
      connection.setTrackSelector(receiveTrackSelectorRef.current);

      const subscription = createEventSubscription();
      let closeReason;

      subscription.subscribe(connection, 'close', ({ detail: reason }) => {
        closeReason = reason;
        onCloseRef.current && onCloseRef.current(port.id);
      });

      subscription.subscribe(connection, 'connectionstatechange', () => {
        log(
          [`%c${port.id}%c: PeerConnection got %cconnectionstatechange%c event`, ...LOG_BADGE_STYLE, ...LOG_VAR_STYLE],
          {
            ...connection.connectionState,
            onStateChange: onStateChangeRef.current
          }
        );

        onStateChangeRef.current && onStateChangeRef.current(port.id, connection.connectionState);
      });

      subscription.subscribe(connection, 'senderschange', () => {
        log([`%c${port.id}%c: PeerConnection got %csenderschange%c event`, ...LOG_BADGE_STYLE, ...LOG_VAR_STYLE], {
          onSendersChange: onSendersChangeRef.current,
          numSenders: Object.keys(connection.getSenders()).length,
          senders: connection.getSenders()
        });

        onSendersChangeRef.current && onSendersChangeRef.current(port.id, connection.getSenders());
      });

      subscription.subscribe(connection, 'streamschange', () => {
        log([`%c${port.id}%c: PeerConnection got %cstreamschange%c event`, ...LOG_BADGE_STYLE, ...LOG_VAR_STYLE], {
          onStreamsChange: onStreamsChangeRef.current,
          numStreams: Object.keys(connection.incomingStreams).length,
          streams: connection.incomingStreams
        });

        onStreamsChangeRef.current && onStreamsChangeRef.current(port.id, connection.incomingStreams);
      });

      return () => {
        subscription.close();
        connection.close();

        closeReason !== 'remote' && port.postMessage({ type: 'reconnect' });
      };
    }, [
      audioBitrate,
      connectionRef,
      onCloseRef,
      onStateChangeRef,
      onStreamsChangeRef,
      port,
      receiveTrackSelectorRef,
      sendTracksRef,
      videoBitrate
    ]);

    useEffect(() => {
      const { current: connection } = connectionRef;

      connection && connection.setTrackSelector(receiveTrackSelector);
    }, [connectionRef, receiveTrackSelector]);

    useEffect(() => {
      const { current: connection } = connectionRef;

      connection && connection.setAvailableTracks(sendTracks);
    }, [connectionRef, sendTracks]);

    return false;
  }
);

export default PeerConnectionProvider;
