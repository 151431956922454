import { useCallback } from 'react';

import useAppDispatch from './useAppDispatch';
import useAppState from './useAppState';

export default function useSelectedAudioInputDeviceId() {
  const dispatch = useAppDispatch();

  const { selectedAudioDeviceId: selectedAudioInputDeviceId } = useAppState();
  const setSelectedAudioInputDeviceId = useCallback(
    value => dispatch({ payload: value, type: 'SET_SELECTED_AUDIO_INPUT_DEVICE_ID' }),
    [dispatch]
  );

  return [selectedAudioInputDeviceId, setSelectedAudioInputDeviceId];
}
