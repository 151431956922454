import { useCallback, useMemo } from 'react';

import DeviceList from './DeviceList';
import useAudioOutputDevices from '../hooks/AudioOutputDevices/useAudioOutputDevices';
import useDeviceCaptureDevices from '../hooks/DeviceCapture/useDevices';
import useSelectedAudioInputDeviceId from '../hooks/app/useSelectedAudioInputDeviceId';
import useSelectedAudioOutputDeviceId from '../hooks/app/useSelectedAudioOutputDeviceId';
import useSelectedVideoInputDeviceIds from '../hooks/app/useSelectedVideoInputDeviceIds';

function labelSorter({ label: x }, { label: y }) {
  return x > y ? 1 : x < y ? -1 : 0;
}

const InputList = () => {
  const [audioOutputDevices] = useAudioOutputDevices();
  const [devices] = useDeviceCaptureDevices();
  const [selectedAudioInputDeviceId, setSelectedAudioInputDeviceId] = useSelectedAudioInputDeviceId();
  const [selectedVideoInputDeviceIds, setSelectedVideoInputDeviceIds] = useSelectedVideoInputDeviceIds();
  const [selectedAudioOutputDeviceId, setSelectedAudioOutputDeviceId] = useSelectedAudioOutputDeviceId();

  const sortedAudioDevices = useMemo(
    () =>
      devices
        .filter(
          ({ deviceId, kind }) => kind === 'audioinput' && deviceId !== 'default' && deviceId !== 'communications'
        )
        .sort(labelSorter),
    [devices]
  );

  const sortedAudioOutputDevices = useMemo(
    () => [{ deviceId: 'default', label: 'Default', kind: 'audiooutput' }, ...audioOutputDevices.sort(labelSorter)],
    [audioOutputDevices]
  );

  const sortedVideoDevices = useMemo(() => devices.filter(({ kind }) => kind === 'videoinput').sort(labelSorter), [
    devices
  ]);

  const selectedAudioDeviceIds = useMemo(() => [selectedAudioInputDeviceId], [selectedAudioInputDeviceId]);

  const handleAudioDeviceChange = useCallback(
    nextSelectedAudioDeviceIds => setSelectedAudioInputDeviceId(nextSelectedAudioDeviceIds[0]),
    [setSelectedAudioInputDeviceId]
  );

  const handleAudioOutputDeviceChange = useCallback(deviceIds => setSelectedAudioOutputDeviceId(deviceIds[0]), [
    setSelectedAudioOutputDeviceId
  ]);

  const selectedAudioOutputDeviceIds = useMemo(() => [selectedAudioOutputDeviceId || 'default'], [
    selectedAudioOutputDeviceId
  ]);

  return (
    <div>
      <section>
        <header>Audio devices</header>
        <hr />
        <DeviceList
          devices={sortedAudioDevices}
          mode="single"
          onChange={handleAudioDeviceChange}
          selectedDeviceIds={selectedAudioDeviceIds}
        />
      </section>
      <br />
      <section>
        <header>Output devices</header>
        <hr />
        {sortedAudioOutputDevices.length ? (
          <DeviceList
            devices={sortedAudioOutputDevices}
            hideParenthesis={false}
            mode="single"
            onChange={handleAudioOutputDeviceChange}
            selectedDeviceIds={selectedAudioOutputDeviceIds}
          />
        ) : (
          <div>Output device is not selectable</div>
        )}
      </section>
      <br />
      <section>
        <header>Video devices</header>
        <hr />
        <DeviceList
          devices={sortedVideoDevices}
          mode="multiple"
          onChange={setSelectedVideoInputDeviceIds}
          selectedDeviceIds={selectedVideoInputDeviceIds}
        />
      </section>
    </div>
  );
};

export default InputList;
