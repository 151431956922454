import { useCallback } from 'react';

import useAppState from './useAppState';

export default function useDeviceSelector() {
  const { selectedAudioDeviceId, selectedVideoDeviceIds } = useAppState();

  return useCallback(
    () => ({ deviceId, kind }) =>
      (kind === 'audioinput' && deviceId === selectedAudioDeviceId) ||
      (kind === 'videoinput' && selectedVideoDeviceIds.includes(deviceId)),
    [selectedAudioDeviceId, selectedVideoDeviceIds]
  );
}
