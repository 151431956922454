import createWebWorker from './utils/createWebWorker';
import fetchLiveViewFrames from './utils/fetchLiveViewFrames';

export default function createMediaStreamForActionCam({ signal, url }) {
  const canvasElement = document.createElement('canvas');

  canvasElement.setAttribute('height', 360);
  canvasElement.setAttribute('width', 640);

  const stream = canvasElement.captureStream(0);
  const track = stream.getVideoTracks()[0];

  (async function () {
    // await new Promise(resolve => {
    //   const imageElement = document.createElement('img');

    //   // imageElement.src = 'sony-logo.svg';
    //   imageElement.src = 'https://i.ytimg.com/vi/UwtTSqTbWzg/maxresdefault.jpg';
    //   imageElement.addEventListener(
    //     'load',
    //     async () => {
    //       if (!signal.aborted) {
    //         const context = canvasElement.getContext('bitmaprenderer');

    //         const bitmap = await createImageBitmap(imageElement)

    //         context.transferFromImageBitmap(bitmap);

    //         // const context = canvasElement.getContext('2d');

    //         // context.fillStyle = 'navy';
    //         // context.fillRect(0, 0, 640, 360);
    //         // context.fill();

    //         // context.drawImage(imageElement, 240, 166, 160, 28);

    //         track.requestFrame();

    //         // await new Promise(resolve => setTimeout(resolve, 5000));

    //         // track.requestFrame();
    //       }

    //       resolve();
    //     },
    //     { once: true }
    //   );
    // });

    const worker = createWebWorker(() => {
      onmessage = ({ data }) => createImageBitmap(new Blob([data])).then(postMessage);
    });

    let context;

    worker.addEventListener('message', ({ data }) => {
      if (canvasElement.height !== data.height || canvasElement.width !== data.width) {
        context = 0;

        canvasElement.setAttribute('height', data.height);
        canvasElement.setAttribute('width', data.width);
      }

      if (!context) {
        context = canvasElement.getContext('bitmaprenderer');
      }

      context.transferFromImageBitmap(data);
      track.requestFrame();
    });

    try {
      if (!signal || !signal.aborted) {
        const frames = fetchLiveViewFrames(url);

        signal &&
          signal.addEventListener('abort', () => {
            frames.cancel();
          });

        for await (let jpegData of frames) {
          worker.postMessage(jpegData);
        }
      }
    } finally {
      worker.terminate();

      canvasElement.remove();
    }
  })();

  return stream;
}
