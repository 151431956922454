import { useCallback } from 'react';

import SelectableDevice from './SelectableDevice';

import './DeviceList.css';

const DeviceList = ({ devices, mode, onChange, selectedDeviceIds }) => {
  const handleDeviceCheckedChange = useCallback(
    ({ checked, device: { deviceId } }) => {
      if (mode === 'multiple') {
        const nextSelectedDeviceIds = new Set(selectedDeviceIds);

        if (checked) {
          nextSelectedDeviceIds.add(deviceId);
        } else {
          nextSelectedDeviceIds.delete(deviceId);
        }

        onChange && onChange(Array.from(nextSelectedDeviceIds));
      } else {
        onChange && onChange(checked ? [deviceId] : []);
      }
    },
    [mode, onChange, selectedDeviceIds]
  );

  return !!devices ? (
    <ul className="device-list">
      {devices.map(device => {
        const { deviceId } = device;

        return (
          <li className="device-list__device" key={deviceId}>
            <SelectableDevice
              checked={selectedDeviceIds.includes(deviceId)}
              device={device}
              key={deviceId}
              onChange={handleDeviceCheckedChange}
            />
          </li>
        );
      })}
    </ul>
  ) : (
    <div>No devices found</div>
  );
};

export default DeviceList;
