import { useRef } from 'react';

const UNINITIALIZED = Symbol();

export default function useLazyRef(initFn) {
  const ref = useRef(UNINITIALIZED);

  if (ref.current === UNINITIALIZED) {
    ref.current = initFn();
  }

  return ref;
}
