import classNames from 'classnames';

import './IconButton.css';

const IconButton = ({ className, disabled, icon, mode, onClick, style, title }) => {
  return (
    <button
      className={classNames(className, 'icon-button', { 'icon-button--warning': mode === 'warning' })}
      disabled={disabled}
      onClick={onClick}
      style={style}
      title={title}
      type="button"
    >
      <i className={`icon-button__icon ms-Icon ms-Icon--${icon}`} />
    </button>
  );
};

export default IconButton;
