import { useCallback, useEffect, useMemo } from 'react';

import AudioSource from '../../ui/AudioSource';
import Context from './Context';
import debug from '../../debug';
import debugFlag from '../../debugFlag';
import useObserveEvent from '../useObserveEvent';

const log = debug('<AudioOutputNode.Provider>');

const AudioOutputNodeProvider = ({ audioContext, children, outputVolume = 1, sinkId }) => {
  const { destinationNode, gainNode } = useMemo(() => {
    const destinationNode = audioContext.createMediaStreamDestination();
    const gainNode = audioContext.createGain();

    gainNode.connect(destinationNode);

    return { destinationNode, gainNode };
  }, [audioContext]);

  useEffect(() => gainNode.disconnect.bind(gainNode, destinationNode), [destinationNode, gainNode]);

  const { oscillatorGainNode, oscillatorNode } =
    useMemo(() => {
      if (debugFlag.outputNode) {
        log('Creating oscillator node for debugging');

        const oscillatorNode = audioContext.createOscillator();
        const oscillatorGainNode = audioContext.createGain();

        oscillatorGainNode.gain.setValueAtTime(0.01, 0);

        oscillatorNode.frequency.setValueAtTime(300, 0);
        oscillatorNode.type = 'triangle';
        oscillatorNode.start();

        oscillatorNode.connect(oscillatorGainNode).connect(gainNode);

        return { oscillatorGainNode, oscillatorNode };
      }
    }, [audioContext, gainNode]) || {};

  useEffect(() => oscillatorGainNode && oscillatorGainNode.disconnect.bind(oscillatorGainNode, gainNode), [
    gainNode,
    oscillatorGainNode
  ]);

  useEffect(() => oscillatorNode && oscillatorNode.disconnect.bind(oscillatorNode, oscillatorGainNode), [
    oscillatorGainNode,
    oscillatorNode
  ]);

  useEffect(() => gainNode.gain.setValueAtTime(outputVolume, 0), [gainNode, outputVolume]);

  const context = useMemo(
    () => ({
      audioContext,
      destinationNode,
      gainNode,
      inputNode: gainNode,
      sinkId,
      stream: destinationNode.stream
    }),
    [audioContext, destinationNode, gainNode, sinkId]
  );

  const resumeAudioContextIfNeeded = useCallback(() => {
    (audioContext.state === 'interrupted' || audioContext.state === 'suspended') && audioContext.resume();
  }, [audioContext]);

  useObserveEvent(window, 'click', resumeAudioContextIfNeeded);
  useObserveEvent(audioContext, 'statechange', resumeAudioContextIfNeeded);

  return (
    <Context.Provider value={context}>
      {children}
      <AudioSource audioContext={audioContext} sinkId={sinkId} srcObject={destinationNode.stream} />
    </Context.Provider>
  );
};

export default AudioOutputNodeProvider;
