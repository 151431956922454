import useContext from './useContext';

export default function useStreams() {
  const { peers } = useContext();

  return [
    Object.entries(peers).reduce(
      (allStreams, [portId, { streams }]) => ({ ...allStreams, [portId]: streams || {} }),
      {}
    )
  ];
}
