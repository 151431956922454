import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import AbortController from 'abort-controller';

import './SplashScreen.css';

import AudioOutputNodeProvider from './hooks/AudioOutputNode/Provider';
import BigButtonScreen from './BigButtonScreen';
import createAudioContext from './utils/createAudioContext';
import debug from './debug';
import styleConsole from './utils/styleConsole';
import useAudioContext from './hooks/AudioOutputNode/useAudioContext';
import useObserveEvent from './hooks/useObserveEvent';
import useOutputVolume from './hooks/app/useOutputVolume';
import useSelectedAudioOutputDeviceId from './hooks/app/useSelectedAudioOutputDeviceId';

const log = debug('<SplashScreen>');

const {
  navigator: { mediaDevices }
} = window;

const Primer = ({ onPrimed }) => {
  useEffect(() => {
    let abortController = new AbortController();

    (async function () {
      if (mediaDevices) {
        try {
          log(`Capturing input devices`);

          const stream = await mediaDevices.getUserMedia({ audio: true, video: true });

          if (abortController.signal.aborted) {
            return;
          }

          const inactivePromise = new Promise(resolve => stream.addEventListener('inactive', resolve, { once: true }));

          log(`Stopping input devices`);

          for (let track of stream.getTracks()) {
            track.stop();
            stream.removeTrack(track);
          }

          log(`Waiting input devices to become inactive`);

          await new Promise(async resolve => {
            for (; stream.active; ) {
              await Promise.race([inactivePromise, new Promise(resolve => setTimeout(resolve, 1000))]);
            }

            resolve();
          });

          log(`Prime completed`);

          onPrimed();
        } catch (err) {
          if (err.message.includes('Requested device not found')) {
            onPrimed();
          } else {
            throw err;
          }
        }
      } else {
        onPrimed();
      }
    })();

    return () => abortController.abort();
  }, [onPrimed]);

  return false;
};

const SplashScreenInternal = ({ children }) => {
  const [audioContext] = useAudioContext();
  // const [stream] = useDestinationStream();

  const [audioContextReady, setAudioContextReady] = useState(audioContext.state === 'running');
  const [inputDevicesReady, setInputDevicesReady] = useState(false);
  const [started, setStarted] = useState(false);

  const handleStartClick = useCallback(() => {
    setStarted(true);
  }, [setStarted]);

  const handleAudioContextStateChange = useCallback(() => {
    log(`AudioContext state changed to %c${audioContext.state}%c`, ...styleConsole('purple'));

    audioContext.state === 'running' && setAudioContextReady(true);
  }, [audioContext]);

  useObserveEvent(audioContext, 'statechange', handleAudioContextStateChange);

  const handlePrimed = useCallback(() => setInputDevicesReady(true), [setInputDevicesReady]);
  const ready = audioContextReady && inputDevicesReady;

  log(
    `Prime status, %caudioContextReady%c = %c${audioContextReady}%c, %cinputDevicesReady%c = %c${inputDevicesReady}%c`,
    ...styleConsole('green'),
    ...styleConsole('purple'),
    ...styleConsole('green'),
    ...styleConsole('purple')
  );

  return (
    <Fragment>
      {ready ? (
        children
      ) : (
        <BigButtonScreen
          className="splash-screen"
          disabled={started}
          icon={started ? 'Rocket' : 'Touch'}
          onClick={handleStartClick}
          label={started ? 'Launching' : 'Start here'}
        >
          {started && <Primer onPrimed={handlePrimed} />}
        </BigButtonScreen>
      )}
    </Fragment>
  );
};

const SplashScreen = ({ children }) => {
  const [outputVolume] = useOutputVolume();
  const [selectedAudioOutputDeviceId] = useSelectedAudioOutputDeviceId();
  const audioContext = useMemo(() => createAudioContext({ latencyHint: 'interactive', sampleRate: 44100 }), []);

  log(
    `ProtectedApp.render: outputVolume=%c${outputVolume}%c sinkId=%c${(selectedAudioOutputDeviceId || '').substr(
      0,
      5
    )}%c`,
    ...styleConsole('purple'),
    ...styleConsole('purple')
  );

  return (
    <AudioOutputNodeProvider
      audioContext={audioContext}
      outputVolume={Math.pow(outputVolume, 2)}
      sinkId={selectedAudioOutputDeviceId}
    >
      <SplashScreenInternal>{children}</SplashScreenInternal>
    </AudioOutputNodeProvider>
  );
};

export default SplashScreen;
