import { useEffect, useRef } from 'react';

import diff from '../data/sagas/utils/diff';

export default function useEffectMany(array, effector) {
  const teardownsRef = useRef([]);

  useEffect(() => {
    return () => {
      for (let { teardown } of teardownsRef.current) {
        teardown && teardown();
        teardownsRef.current = [];
      }
    };
  }, [effector]);

  useEffect(() => {
    const { added, removed } = diff(
      teardownsRef.current.map(({ element }) => element),
      array
    );

    for (let element of removed) {
      const index = teardownsRef.current.findIndex(target => target.element === element);
      const { teardown } = teardownsRef.current[index];

      teardown && teardown();
      teardownsRef.current.splice(index, 1);
    }

    for (let element of added) {
      const teardown = effector(element);

      teardownsRef.current.push({ element, teardown });
    }
  }, [array, effector, teardownsRef]);
}
