import { forwardRef, useCallback, useLayoutEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import './VideoSource.css';
import createEventSubscription from '../utils/createEventSubscription';
import useObserveEvent from '../hooks/useObserveEvent';

const VideoSource = forwardRef(
  ({ className, onEnterPictureInPicture, onLeavePictureInPicture, srcObject }, refFromProp) => {
    const [playing, setPlaying] = useState(false);
    const refFromLocal = useRef();
    const ref = refFromProp || refFromLocal;

    const handleInactive = useCallback(() => {
      setPlaying(false);
    }, [setPlaying]);

    const handlePlay = useCallback(() => {
      setPlaying(true);
    }, [setPlaying]);

    const handleRemoveTrack = useCallback(
      ({ target }) => {
        target.inactive && handleInactive();
      },
      [handleInactive]
    );

    const resumePlayback = useCallback(() => {
      const { current } = ref;

      try {
        current && current.paused && current.play();
      } catch (err) {}
    }, [ref]);

    useObserveEvent(window, 'click', resumePlayback);

    useLayoutEffect(() => {
      const { current } = refFromProp || refFromLocal;

      if (current && srcObject) {
        const subscription = createEventSubscription();

        current.srcObject = srcObject;
        subscription.subscribe(srcObject, 'enterpictureinpicture', onEnterPictureInPicture);
        subscription.subscribe(srcObject, 'inactive', handleInactive);
        subscription.subscribe(srcObject, 'leavepictureinpicture', onLeavePictureInPicture);
        subscription.subscribe(srcObject, 'removetrack', handleRemoveTrack);
        subscription.subscribe(current, 'play', handlePlay);
        subscription.subscribe(current, 'suspend', handleInactive);
        // subscription.subscribe(current, ['abort', 'emptied', 'ended', 'stalled', 'suspend', 'waiting', 'seeked', 'seeking', 'ratechange', 'durationchange'], ({ type }) => console.log(type));

        return () => {
          subscription.close();

          try {
            current.srcObject = null;
          } catch (err) {}
        };
      }
    }, [
      handleInactive,
      handleRemoveTrack,
      onEnterPictureInPicture,
      onLeavePictureInPicture,
      refFromLocal,
      refFromProp,
      setPlaying,
      srcObject
    ]);

    return (
      <div className={classNames('video-source', className)}>
        <video
          autoPlay={true}
          className="video-source__video-element"
          controls={false}
          muted={true}
          playsInline={true}
          ref={ref}
        />
        {!playing && (
          <div className="video-source__splash">
            <i className="video-source__splash-icon ms-Icon ms-Icon--Plug" />
          </div>
        )}
      </div>
    );
  }
);

export default VideoSource;
