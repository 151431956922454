import classNames from 'classnames';

import './StatusIcon.css';

const StatusIcon = ({ className, icon, label, mode, title }) => {
  return (
    <div
      className={classNames(
        'status-icon',
        { 'status-icon--emphasis': mode === 'emphasis', 'status-icon--warning': mode === 'warning' },
        className
      )}
    >
      {!!icon && <i className={`status-icon__icon ms-Icon ms-Icon--${icon}`} title={title} />}
      {typeof label !== 'undefined' && <div className="status-icon__label">{label}</div>}
    </div>
  );
};

export default StatusIcon;
